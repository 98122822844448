import { reactive } from 'vue';

export const criarAtividade = () => {
  return reactive({
    id: 0,
    idep: 0,
    cdatividade: 0,
    nmatividade: '',
    pla13subtipo: '',
    obs: '',
    ativo: 'S',
    idusuario: 0
  });
};