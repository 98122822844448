<template>
    <div class="layout">
        <aside class="sidebar" :class="{ collapsed: isCollapsed }">
            <ul>
                <li @click="toggleMenu">
                    <img :src="menuIcon" alt="Toggle Menu" />
                    <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
                </li>

                <li @mouseover="showCadastrosMenu = true" @mouseleave="showCadastrosMenu = false">
                    <img src="@/assets/icons/menuCadastros.png" />
                    <span>Cadastros</span>
                    <div v-if="showCadastrosMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('AtividadesListaPage', 'LISTA DE ATIVIDADES')">
                                <span>Atividades</span>
                            </li>
                            <li @click="selectOption('ContasEmailListaPage', 'CONTAS DE EMAIL')">
                                <span>Contas de Email</span>
                            </li>
                            <li @click="selectOption('FeriadosListaPage', 'FERIADOS')">
                                <span>Feriados</span>
                            </li>
                            <li @click="selectOption('MunicipiosListaPage', 'MUNICÍPIOS')">
                                <span>Municípios</span>
                            </li>
                            <li @click="selectOption('TpDocsListaPage', 'TIPOS DE DOCUMENTOS')">
                                <span>Tipos de Documentos</span>
                            </li>
                            <li @click="selectOption('UsuariosAdminListaPage', 'ADMINISTRADORES ESTABELECIMENTOS')">
                                <span>Usuários Administradores</span>
                            </li>
                            <li @click="selectOption('UsuariosSupervisoresListaPage', 'SUPERVISORES')">
                                <span>Usuários Supervisores</span>
                            </li>
                            <li @click="selectOption('UsuariosInspetoresListaPage', 'INSPETORES')">
                                <span>Usuários Inspetores</span>
                            </li>
                            <li @click="selectOption('UsuariosRTListaPage', 'RESPONSÁVEIS TÉCNICOS')">
                                <span>Usuários RTs</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo((userData.id === 1) ? 'EpListaPage' : 'EpEditPage', 'ASSOCIAÇÃO/MUNICÍPIO')">
                    <img src="@/assets/icons/menuSims.png" />
                    <span>Instituição</span>
                </li>
                <li @click="setComponenteAtivo('SimsSupervisorListaPage', 'SERVIÇOS DE INSPEÇÃO')">
                    <img src="@/assets/icons/menuSims.png" />
                    <span>Serviços de Inspeção</span>
                </li>
                <li @click="setComponenteAtivo('EstabelecimentosSupervisorLista', 'ESTABELECIMENTOS')">
                    <img src="@/assets/icons/menuEstabelecimentos.png" />
                    <span>Estabelecimentos</span>
                </li>
                <li @click="setComponenteAtivo('UsuarioEditPage', 'EDITAR DADOS')">
                    <img src="@/assets/icons/menuEditUser.png" />
                    <span>Alteração de Cadastro</span>
                </li>
                <li @click="setComponenteAtivo('AlterarLoginPage', 'ALTERAÇÃO DE LOGIN E SENHA')">
                    <img src="@/assets/icons/menuPass.png" />
                    <span>Redefinir Login e Senha</span>
                </li>
                <li class="logout" @click="confirmLogout">
                    <img src="@/assets/icons/menuLogout.png" />
                    <span>Desconectar</span>
                </li>
            </ul>
        </aside>

        <main class="content">
            <header class="header">
                <div class="header-title">{{ nmTitulo ? nmTitulo : 'SUPERVISOR' }}</div>
                <div class="user-info">
                    <div class="user-name">{{ userData.nmusuario }}</div>
                    <div class="establishment-name">{{ userData.nmep }}</div>
                    <div class="establishment-name">Tempo restante do token: {{ timeRemaining }}</div>
                </div>
            </header>
            <div class="main-content">
                <slot>
                    <div v-if="nmComponenteAtivo=='AlterarLoginPage'"><AlterarLoginPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='AtividadesListaPage'"><AtividadesListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='ContasEmailListaPage'"><ContasEmailListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EpEditPage'"><EpEditPage :idEdicao="userData.idep" @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EpListaPage'"><EpListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EstabelecimentosSupervisorLista'"><EstabelecimentosSupervisorLista/></div>
                    <div v-else-if="nmComponenteAtivo=='FeriadosListaPage'"><FeriadosListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='MunicipiosListaPage'"><MunicipiosListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='SimsSupervisorListaPage'"><SimsSupervisorListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='TpDocsListaPage'"><TpDocsListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuarioEditPage'"><UsuarioEditPage :idEdicao="userData.id" @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosAdminListaPage'"><UsuariosAdminListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosInspetoresListaPage'"><UsuariosInspetoresListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosRTListaPage'"><UsuariosRTListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosSupervisoresListaPage'"><UsuariosSupervisoresListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else><ResumoGeralPage /></div>
                </slot>
            </div>
        </main>
    </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useStore } from "vuex";
import AlterarLoginPage from '@/components/cadastros/AlterarLoginPage.vue'
import AtividadesListaPage from '@/components/listas/AtividadesListaPage.vue';
import ContasEmailListaPage from '@/components/listas/ContasEmailListaPage.vue';
import EpEditPage from '@/components/cadastros/formularios/EpEditPage.vue'
import EpListaPage from '@/components/listas/EpListaPage.vue';
import EstabelecimentosSupervisorLista from '@/components/listas/EstabelecimentosSupervisorLista.vue';
import FeriadosListaPage from '../listas/FeriadosListaPage.vue';
import MunicipiosListaPage from '../listas/MunicipiosListaPage.vue';
import ResumoGeralPage from '../dashboards/ResumoGeralPage.vue';
import SimsSupervisorListaPage from '@/components/listas/SimsSupervisorListaPage.vue';
import TpDocsListaPage from '../listas/TpDocsListaPage.vue';
import UsuarioEditPage from '@/components/cadastros/formularios/UsuarioEditPage.vue';
import UsuariosAdminListaPage from '../listas/UsuariosAdminListaPage.vue';
import UsuariosInspetoresListaPage from '../listas/UsuariosInspetoresListaPage.vue';
import UsuariosRTListaPage from '../listas/UsuariosRTListaPage.vue';
import UsuariosSupervisoresListaPage from '../listas/UsuariosSupervisoresListaPage.vue';

export default {
    name: 'MainSupervisor',
    components: {
        AlterarLoginPage,
        AtividadesListaPage,
        ContasEmailListaPage,
        EpEditPage,
        EpListaPage,
        EstabelecimentosSupervisorLista,
        FeriadosListaPage,
        MunicipiosListaPage,
        ResumoGeralPage,
        SimsSupervisorListaPage,
        TpDocsListaPage,
        UsuarioEditPage,
        UsuariosAdminListaPage,
        UsuariosInspetoresListaPage,
        UsuariosRTListaPage,
        UsuariosSupervisoresListaPage
    },
    computed: {
        menuIcon() {
            return this.isCollapsed
                ? require('@/assets/icons/menuExpand.png')
                : require('@/assets/icons/menuCollapse.png');
        }
    },
    setup() {
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const showCadastrosMenu = ref(false);
        const store = useStore();
        const timeRemaining = ref(null);
        const userData = computed(() => store.getters.getUserData);
        let timer = null;

        const confirmLogout = () => {resetLogin();}

        const resetLogin = () => { store.dispatch('clearSessionData'); }

        const toggleMenu = () => { isCollapsed.value = !isCollapsed.value; }

        const selectOption = (page, titulo) => {
            nmComponenteAtivo.value = page;
            nmTitulo.value = titulo;
            showCadastrosMenu.value = false; // Fecha o submenu após a seleção
        }

        const setComponenteAtivo = (nome, titulo) => {
          nmComponenteAtivo.value = nome;
          nmTitulo.value = titulo;
        }

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        timeRemaining.value = `${hours}h ${minutes}m ${seconds}s`;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });

        return {
            isCollapsed,
            nmComponenteAtivo,
            nmTitulo,
            showCadastrosMenu,
            timeRemaining,
            userData,
            confirmLogout,
            toggleMenu,
            selectOption,
            setComponenteAtivo
        };
    }
};
</script>

<style scoped src="@/assets/css/main.css"></style>
