<template>
  <div class="form-item">
    <label class="label-caption" for="municipio">Municipio</label>
    <select :value="municipioSelecionado" @change="atualizarMunicipio" id="municipio" class="form-control" v-bind="$attrs">
      <option v-for="municipio in municipios" :key="municipio.id" :value="municipio.id">{{ municipio.nmmunicipio }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    municipios: {
      type: Array,
      required: true
    },
    municipioSelecionado: {
      type: Number,
      default: null
    }
  },

  methods: {
    atualizarMunicipio(event) {
      this.$emit('update:municipioSelecionado', Number(event.target.value));
    }
  }
};
</script>