import { reactive } from 'vue';

export const criarSim = () => {
  return reactive({
    id: 0,
    idep: 0,
    cdsim: 0,
    nmsim: '',
    cnpj: '',
    endereco: '',
    nmendereco: 0,
    complemento: '',
    bairro: '',
    cep: '',
    idmunicipio: 0,
    fone: '',
    email: '',
    nmlogo: '',
    ativo: '',
    rptitulo: '',
    rpsubtitulo: '',
    idusuario: 0
  });
};
