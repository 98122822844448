<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <EstabelecimentoSelect :lsDados="lsEstabelecimentos" v-model:estabelecimentoSelecionado="estabelecimentoSelecionado" />
        <button type="button" @click="openReport">IMPRIMIR</button>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { genericPost, genericReport } from '@/funcoes/api';
import { usuarioLogado } from '@/composables/usuario';
import EstabelecimentoSelect from '../selects/EstabelecimentoSelect.vue';

export default{
    name: 'EstebelecimentosParecerReportPage',
    components: { EstabelecimentoSelect },
    setup(){
        const estabelecimentoSelecionado = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsEstabelecimentos = ref([]);

        const inicializar = async() => {
            try{
                isLoading.value = true;
                try{
                    const rEst = await genericPost('/listas/estabelecimentos', { idep: login.userData.value.idep, idsim: login.userData.value.idsim });
                    lsEstabelecimentos.value = rEst;
                    lsEstabelecimentos.value.unshift({id: 0, nmestabelecimento: 'TODOS'});
                }catch(error){console.error(error);}
            }finally{isLoading.value=false;}
        }

        const openReport = async() => {
            try{
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'estabelecimentos_parecer',
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: (estabelecimentoSelecionado.value ? estabelecimentoSelecionado.value.id : 0),
                    idusuario: login.userData.value.idusuario
                };
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){console.log(`ATENÇÃO: ${error.message}`);
            } finally {isLoading.value = false;}
        }

        onMounted(()=>{inicializar();});

        return { estabelecimentoSelecionado, isLoading, lsEstabelecimentos, openReport };
    }
}
</script>