export default {
  beforeMount(el) {
    el.addEventListener('input', function (e) {
      let value = e.target.value.replace(/\D/g, '');

      if (value) {
        value = parseInt(value).toLocaleString('pt-BR');
      }

      if (e.target.value !== value) {
        e.target.value = value;
        const event = new Event('input', { bubbles: true });
        el.dispatchEvent(event);
      }
    });

    el.addEventListener('blur', function (e) {
      let value = e.target.value.replace(/\D/g, '');

      if (value) {
        value = parseInt(value).toLocaleString('pt-BR');
      }

      if (e.target.value !== value) {
        e.target.value = value;
        const event = new Event('input', { bubbles: true });
        el.dispatchEvent(event);
      }
    });
  }
};