<template>
    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ id }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmusuario">Nome</label>
                    <input type="text" v-model="nmusuario" id="nmusuario" required maxlength="80" @input="nmusuario = nmusuario.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption" for="cpf">CPF</label>
                    <input type="text" class="input-center" v-model="cpf" id="cpf" required v-cpf maxlength="14" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item flexible">
                    <label class="label-caption" for="endereco">Endereço</label>
                    <input type="text" v-model="endereco" id="endereco" maxlength="80" @input="endereco = endereco.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="nmendereco">Número</label>
                    <input type="text" class="input-right" v-model="formatedNmEndereco" id="nmendereco" v-number maxlength="8" />
                </div>  
                <div class="form-item">
                    <label class="label-caption" for="complemento">Complemento</label>
                    <input type="text" v-model="complemento" id="complemento" maxlength="80" @input="complemento = complemento.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="bairro">Bairro</label>
                    <input type="text" v-model="bairro" id="bairro" maxlength="60" @input="bairro = bairro.toUpperCase()" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="nmmunicipio">Município</label>
                    <input type="text" v-model="nmmunicipio" id="nmmunicipio" maxlength="80" @input="nmmunicipio = nmmunicipio.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 100px;">
                    <label class="label-caption" for="uf">UF</label>
                    <input type="text" class="input-center" v-model="uf" id="uf" maxlength="2" @input="uf = uf.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 100px;">
                    <label class="label-caption" for="cep">CEP</label>
                    <input type="text" class="input-center" v-model="cep" id="cep" v-cep maxlength="10" />
                </div>
            </div>
            
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 180px;">
                    <label class="label-caption" for="celular">Celular</label>
                    <input type="text" v-model="celular" id="celular" v-phone maxlength="15" />
                </div>
                <div class="form-item" style="flex: 0 0 180px;">
                    <label class="label-caption" for="fone">Telefone</label>
                    <input type="text" v-model="fone" id="fone" v-phone maxlength="15" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="email">Email Principal</label>
                    <input type="email" v-model="email" id="email" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="email2">Email Alternativo</label>
                    <input type="email" v-model="email2" id="email2" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="registroorgao">Órgão de Registro</label>
                    <input type="text" v-model="registroorgao" id="registroorgao" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="registronumero">Nro Registro</label>
                    <input type="text" class="input-right" v-model="formatedRegistroNumero" id="registronumero" v-number maxlength="10" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="matriculanumero">Nro Matrícula</label>
                    <input type="text" class="input-right" v-model="formatedMatriculaNumero" id="matriculanumero" v-number maxlength="10" />
                </div>        
                <div v-if="idEdicao==0" class="form-item">
                    <label class="label-caption" for="login">Login Inicial</label>
                    <input type="text" v-model="login" id="login" />
                </div>
                <div v-if="idEdicao==0" class="form-item">
                    <label class="label-caption" for="senha">Senha Inicial</label>
                    <input type="text" v-model="senha" id="senha" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Usuário Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="isAtivo" />
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" class="btn-cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>    
    </div>
</template>

<script>
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { criarUsuario } from '@/models/usuario';
import { computed, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById, loginDisponivel } from '@/funcoes/api'; 

export default {
    name: 'UsuarioEditPage',
    mixins: [numberFormatMixin],

    computed: {
        formatedNmEndereco:{
            get(){return this.formatNumber(this.nmendereco);},
            set(value){this.nmendereco = this.unformatNumber(value);}
        },
        formatedRegistroNumero:{
            get(){return this.formatNumber(this.registronumero);},
            set(value){this.registronumero = this.unformatNumber(value);}
        },
        formatedMatriculaNumero:{
            get(){return this.formatNumber(this.matriculanumero);},
            set(value){this.matriculanumero = this.unformatNumber(value);}
        }
    },

    emits: ['close', 'salvo'],

    props: {
        idEdicao: {type: Number, default: 0},
        idEstabelecimento: {type: Number, default: 0},
        perfilEstabelecimento: {type: Boolean, default: false},
        perfilGestor: {type: Boolean, default: false},
        perfilInspetor: {type: Boolean, default: false},
        perfilRt: {type: Boolean, default: false},
        perfilSupervisor: {type: Boolean, default: false}
    },

    methods: {
        submitForm() {this.enviarDados();},
        cancelForm() {this.cancelar();}
    },

    setup(props, { emit }){
        const usuario = criarUsuario();
        const login = usuarioLogado();

        const cancelar = async() => {emit('close');}

        const enviarDados = async() => {
            try{
                if (usuario.id == 0){
                    const ok = await loginDisponivel(0, usuario.login);
                    if (!ok){
                        alert('Nome de usuário já está sendo utilizado, necessário cadastrar outro');
                        return;
                    }
                }
                const response = await genericPut('/cad/usuario', usuario);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        const isAtivo = computed({
            get() {return usuario.ativo === "S";},
            set(value) {usuario.ativo = value ? "S" : "N";}
        });

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    const req = await getCadastroById('usuario', props.idEdicao);
                    Object.assign(usuario, req);
                }catch (error){console.error('Erro leitura dos dados', error);}
            }else{
                usuario.idep                  = login.userData.value.idep;
                usuario.idestabelecimento     = props.idEstabelecimento;
                usuario.perfilestabelecimento = (props.perfilEstabelecimento)?'S':'N';
                usuario.perfilgestor          = (props.perfilGestor)?'S':'N';
                usuario.perfilinspetor        = (props.perfilInspetor)?'S':'N';
                usuario.perfilrt              = (props.perfilRt)?'S':'N';
                usuario.perfilsupervisor      = (props.perfilSupervisor)?'S':'N';
            }
            usuario.idusuario                 = login.userData.value.id;
        };

        lerDados();

        return { ...toRefs(usuario), cancelar, enviarDados, isAtivo, lerDados };
    }
};
</script>

<style scoped src="@/assets/css/layout.css"></style>