<!-- src/components/MainIndefinido.vue -->
<template>
    <div class="container">
      <div class="alert">
        <h3>PERFIL DE USUÁRIO INDEFINIDO</h3>
        <p>
          Parece que seu perfil de usuário não foi definido corretamente. Por favor, entre em contato com o administrador do sistema para obter assistência.
        </p>
      </div>
      <button @click="resetLogin" class="reset-button">USAR OUTRO LOGIN E SENHA</button>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    name: 'MainIndefinido',
    setup() {
      const store = useStore();
      const userData = computed(() => store.getters.getUserData);
      const resetLogin = () => {
        store.dispatch('clearSessionData');
      };
      return { resetLogin, userData };
    }
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .alert {
    background-color: #ffdddd;
    color: #d8000c;
    padding: 20px;
    border: 1px solid #d8000c;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .alert h3 {
    margin: 0 0 10px 0;
  }
  
  .alert p {
    margin: 0;
  }
  
  .reset-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reset-button:hover {
    background-color: #0056b3;
  }
  </style>
  