<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao">
        <table>
            <thead>
                <tr>
                    <th>Tipo Documento</th>
                    <th>Nome</th>
                    <th>Validade</th>
                    <th>Situação</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ obj.nmtpdoc }}</td>
                    <td>{{ obj.nmdoc }}</td>
                    <td>{{ formatDate(obj.dtvalidade) }}</td>
                    <td>{{ obj.situacaonm }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(obj)"></button>
                        <button v-if="obj.idanexo" class="btn-icon btn-icon--pdf" @click="lerAnexo(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div class="buttons margin-top10">
            <button type="button" @click="closeForm()">Sair</button>
        </div>
    </div>


    <div v-if="modoEdicao">
        <AnexoUpload :idTpDoc="idTpDoc" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import AnexoUpload from './AnexoUploadPage.vue';

export default{
    name: 'EstabDocsListaPage',
    emits: ['close'],
    components:{AnexoUpload},
    methods:{
        formatDate(dateStr){
            if (!dateStr) return null;
            const[year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        }
    },

    setup(_, { emit }){
        const idTpDoc = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const modoEdicao = ref(false);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idTpDoc.value = obj.idtpdoc;
            modoEdicao.value = true;
        };

        const lerAnexo = async(obj) => {
            try{
                isLoading.value = true;
                const dados = {id: obj.idanexo};
                const req = await genericPost('/main/anexo_base64', dados);
                const pdfBase64 = req.anexo;
                const pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+
                    encodeURIComponent(pdfBase64) +
                    "'></iframe>'"
                );
            } catch (error) {console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/dados/estabelecimentos_parecer', {idep: login.userData.value.idep, idestabelecimento: login.userData.value.idestabelecimento});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        lerDados();

        return { closeForm, editarCadastro, lerAnexo, lerDados, idTpDoc, isLoading, lsDados, modoEdicao };
    }
}
</script>
