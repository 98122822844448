<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando dados...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ id }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmep">Nome da EP</label>
                    <input type="text" v-model="nmep" id="nmep" required maxlength="80" @input="nmep = nmep.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 200px;">
                    <label class="label-caption" for="cnpj">CNPJ</label>
                    <input type="text" v-model="cnpj" id="cnpj" required v-cnpj maxlength="18" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item flexible">
                    <label class="label-caption" for="endereco">Endereço</label>
                    <input type="text" v-model="endereco" id="endereco" required maxlength="80" @input="endereco = endereco.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="nmendereco">Número</label>
                    <input type="text" class="input-right" v-model="formatedNmEndereco" id="nmendereco" v-number maxlength="7" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="complemento">Complemento</label>
                    <input type="text" v-model="complemento" id="complemento" maxlength="80" @input="complemento = complemento.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="bairro">Bairro</label>
                    <input type="text" v-model="bairro" id="bairro" maxlength="60" @input="bairro = bairro.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="cep">CEP</label>
                    <input type="text" v-model="cep" id="cep" v-cep maxlength="10" />
                </div>
                <MunicipioSelect class="form-item"
                    :municipios="lsMunicipios"
                    v-model:municipioSelecionado="idmunicipio" required />
            </div>

            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 200px;">
                    <label class="label-caption" for="fone">Telefone</label>
                    <input type="text" v-model="fone" id="fone" v-phone maxlength="14" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="email">Email</label>
                    <input type="email" v-model="email" id="email" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="emaildadosproducao">Email de Produção</label>
                    <input type="email" v-model="emaildadosproducao" id="emaildadosproducao" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="emailsupervior">Email do Supervisor</label>
                    <input type="email" v-model="emailsupervior" id="emailsupervior" />
                </div>
                <div class="form-item" style="flex: 0 0 200px;">
                    <label class="label-caption" for="nmlogo">Nome da Logo</label>
                    <input type="text" v-model="nmlogo" id="nmlogo" required maxlength="255" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="rptitulo">Título Relatórios</label>
                    <input type="text" v-model="rptitulo" id="rptitulo" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="rpsubtitulo">Subtítulo Relatórios</label>
                    <input type="text" v-model="rpsubtitulo" id="rpsubtitulo" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" @click="cancelForm">Cancelar</button>
            </div>            
        </form>
    </div>
</template>
  
<script>
import { criarEp } from '@/models/ep';
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import MunicipioSelect from '@/components/selects/MunicipioSelect.vue';

export default {
    name: 'EpEditPage',
    components:{MunicipioSelect},
    computed: {
        formatedNmEndereco:{
            get(){return this.formatNumber(this.nmendereco);},
            set(value){this.nmendereco = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props:{
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const ep = criarEp();
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsMunicipios = ref([]);

        const ckAtivo = computed({
            get() {return ep.ativo === 'S';},
            set(value) {ep.ativo = value ? 'S' : 'N';}
        });

        const cancelForm = () => {emit('close');}

        const submitForm = async() => {
            try{
                const response = await genericPut('/cad/ep', ep);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        const lerDados = async() => {
            if (login.userData){
                try{
                    isLoading.value = true;
                    try{
                        const reqMun = await genericPost('/listas/municipios', { idep: props.idEdicao, ativo: 'S' });
                        lsMunicipios.value = reqMun;
                    }catch (error){console.error('Erro leitura dos dados', error);}
                    
                    try{
                        const req = await getCadastroById('ep', props.idEdicao);
                        Object.assign(ep, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }
        };

        lerDados();

        return { ...toRefs(ep), isLoading, lsMunicipios, ckAtivo, cancelForm, submitForm };
    }
};
</script>