<template>
    <div class="user-detail">
        <div class="form-row">
            <div class="form-group small-field">
                <label class="label-caption">Código:</label>
                <div class="field-label field-label-end" >{{ user.id }}</div>
            </div>

            <div class="form-group large-field">
                <label class="label-caption">Nome:</label>
                <div class="field-label">{{ user.nmusuario }}</div>
            </div>

            <div class="form-group medium-field">
                <label class="label-caption">CPF:</label>
                <div class="field-label field-label-center">{{ user.cpf }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">Status:</label>
                <div class="field-label field-label-center">{{ user.xativo }}</div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group">
                <label class="label-caption">Endereço:</label>
                <div class="field-label">{{ user.endereco }}</div>
            </div>

            <div class="form-group">
                <label class="label-caption">Bairro:</label>
                <div class="field-label">{{ user.bairro || 'Não informado' }}</div>
            </div>
  
            <div class="form-group">
                <label class="label-caption">Município:</label>
                <div class="field-label">{{ user.nmmunicipio }}</div>
            </div>
        
            <div class="form-group thinny-field">
                <label class="label-caption">UF:</label>
                <div class="field-label field-label-center">{{ user.uf }}</div>
            </div>
        </div>
  
        <div class="form-row">
            <div class="form-group  small-field">
                <label class="label-caption">CEP:</label>
                <div class="field-label">{{ user.cep }}</div>
            </div>
        
            <div class="form-group  small-field">
                <label class="label-caption">Telefone:</label>
                <div class="field-label">{{ user.fone }}</div>
            </div>
        
            <div class="form-group  small-field">
                <label class="label-caption">Celular:</label>
                <div class="field-label">{{ user.celular }}</div>
            </div>
        
            <div class="form-group">
                <label class="label-caption">E-mail:</label>
                <div class="field-label">{{ user.email }}</div>
            </div>
        </div>

        
  
        <div class="form-row">
            <div class="form-group">
                <label class="label-caption">Perfil:</label>
                <div class="field-label">{{ user.xperfil }}</div>
            </div>

            <div class="form-group">
                <label class="label-caption">Registro Órgão:</label>
                <div class="field-label">{{ user.registro_orgao }}</div>
            </div>
        
            <div class="form-group">
                <label class="label-caption">Número Registro:</label>
                <div class="field-label">{{ user.registro_numero }}</div>
            </div>
        
            <div class="form-group">
                <label class="label-caption">Matrícula Número:</label>
                <div class="field-label">{{ user.matricula_numero }}</div>
            </div>
        </div>
        
        <div class="form-row">
            <div class="form-group">
                <label class="label-caption">SIM:</label>
                <div class="field-label">{{ user.nmsim }}</div>
            </div>

            <div class="form-group">
                <label class="label-caption">Estabelecimento:</label>
                <div class="field-label">{{ user.nmestabelecimento }}</div>
            </div>
        </div>
  
        <div class="buttons">
          <button type="button" @click="openModal">Redefinir Senha</button>
          <button type="button" @click="closePage">Fechar</button>
        </div>
        
    </div>

    <!-- Modal de redefinição de senha -->
    <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
            <h4>Redefinir Login e Senha</h4>
            <form @submit.prevent="redefinirLoginSenha">
                <div class="form-group">
                    <label class="label-caption">Novo Login:</label>
                    <input type="text" autocomplete="username" maxlength="20" v-model="novoLogin" required />
                </div>

                <div class="form-group">
                    <label class="label-caption">Nova Senha:</label>
                    <input type="password" autocomplete="new-password" maxlength="20" v-model="novaSenha" required />
                </div>

                <div class="form-group">
                    <label class="label-caption">Confirmar Senha:</label>
                    <input type="password" autocomplete="new-password" maxlength="20" v-model="novaSenha2" required />
                </div>

                <div class="buttons">
                    <button type="submit">Salvar</button>
                    <button type="cancel" @click="closeModal">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { genericPut, loginDisponivel } from '@/funcoes/api'; 

export default {
  name: 'UserDetail',
  emits: ['close'],
  props: {user: Object},
  setup(props, { emit }){
    const novoLogin = ref('');
    const novaSenha = ref('');
    const novaSenha2 = ref('');
    const showModal = ref(false);

    const closePage = () => {emit('close');}

    const closeModal = () => {
        showModal.value = false;
    }
    
    const openModal = () => {
        novoLogin.value = '';
        novaSenha.value = '';
        novaSenha2.value = '';
        showModal.value = true;
    }

    const redefinirLoginSenha = async() =>{
        if (novaSenha.value !== novaSenha2.value){
            alert('As senhas não coincidem.');
        }else{
            try{
                const ok = await loginDisponivel(props.user.id, novoLogin.value);
                if (!ok){
                    alert('Nome de usuário já está sendo utilizado, necessário cadastrar outro');
                    return;
                }
                await genericPut('/cad/usuario', { id: props.user.id, login: novoLogin.value, senha: novaSenha.value });
            }catch (error){alert('Não foi possível alterar os dados de acesso'); console.error('Error:', error);}
            showModal.value = false;
        }
    }

    return { novoLogin, novaSenha, novaSenha2, showModal, closePage, closeModal, openModal, redefinirLoginSenha };
  }
};
</script>

<style scoped>

input {
    width: 100%; /* Faz o input ocupar 100% da largura do contêiner */
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Garante que padding e borda sejam considerados no tamanho total do input */
}

/* Estilos do modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
}

.user-detail {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.form-group {
    margin-bottom: 15px;
    flex: 1; /* Faz os campos terem larguras proporcionais */
}

/* Definindo tamanhos diferentes para os campos */
.thinny-field {
    flex: 0 0 5%; /* 5% da linha, não cresce nem encolhe */
}

.small-field {
    flex: 0 0 15%; /* 15% da linha, não cresce nem encolhe */
}

.medium-field {
    flex: 0 0 30%; /* 30% da linha, não cresce nem encolhe */
}

.large-field {
    flex: 1; /* Ocupa o restante do espaço disponível */
}
</style>
