<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <RangeDataSelect @rangeChange="rangeDataChange" />
        <EstabelecimentoSelect :lsDados="lsEstabelecimentos" v-model:estabelecimentoSelecionado="estabelecimentoSelecionado" />
        <TpDocSelect :lsDados="lsTpDocs" v-model:tpdocSelecionado="tpdocSelecionado" />
        <button type="button" @click="lerDados">Pesquisar</button>
        <button type="button" @click="closePage">Encerrar</button>
    </div>

    <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
    
    <div class="margin-top10">
        <table>
            <thead><tr>
                <th>Data</th>
                <th>Tipo</th>
                <th>Estabelecimento</th>
                <th>Validade</th>
                <th>Situação</th>
                <th>Ações</th>
            </tr></thead>

            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.idregistro" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ formatDate(obj.dtanexo) }}</td>
                    <td>{{ obj.nmtpdoc }}</td>
                    <td>{{ obj.nmestabelecimento }}</td>
                    <td>{{ formatDate(obj.dtvalidade) }}</td>
                    <td>{{ obj.situacaonm }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--pdf" @click="lerAnexo(obj)"></button>
                        <button v-if="obj.situacao === 1" class="btn-icon btn-icon--checkMark" @click="openAnalise(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="showAnalise" class="modal-overlay">
        <div class="modal-content">
            <h4>Análise de Documentação</h4>
            <form>
                <div class="form-group">
                    <div class="form-item">
                        <label class="label-caption">Data de Validade:</label>
                        <input type="date" v-model="dtValidade" required />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-item">
                        <label class="label-caption">Justificativa</label>
                        <textarea v-model="obsAnalise" maxlength="1000" rows="5" style="resize: none;" required/>
                    </div>
                </div>

                <div class="buttons form-group">
                    <button type="button" class="btn-confirm" @click="salvarAnalise('S')">APROVAR</button>
                    <button type="button" class="btn-cancel" @click="salvarAnalise('N')">NÃO APROVAR</button>
                </div>

                <div class="buttons">
                    <button @click="showAnalise = false">SAIR</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost, genericPut } from '@/funcoes/api';
import { strDataHoraAtual } from '@/funcoes/utils';
import EstabelecimentoSelect from '../selects/EstabelecimentoSelect.vue';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import RangeDataSelect from '@/components/selects/RangeDataSelect.vue';
import TpDocSelect from '../selects/TpDocSelect.vue';

export default{
    name: 'AnexosInspetorListaPage',
    emits:['close'],
    components:{ EstabelecimentoSelect, MensagemAlerta, RangeDataSelect, TpDocSelect },
    methods:{
        formatDate(dateStr){
            const[year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        },
    },
    setup(_, {emit}){
        const dtValidade = ref(null);
        const errorMessage = ref(null);
        const estabelecimentoSelecionado = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const lsEstabelecimentos = ref([]);
        const lsTpDocs = ref([]);
        const objSelecionado = ref(null);
        const obsAnalise = ref('');
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});
        const showAnalise = ref(false);
        const tpdocSelecionado = ref(null);

        const closePage = () => {emit('close');}

        const inicializar = async() => {
            try{
                isLoading.value = true;
                try{
                    const rEst = await genericPost('/listas/estabelecimentos', { idep: login.userData.value.idep, idsim: login.userData.value.idsim });
                    lsEstabelecimentos.value = rEst;
                    lsEstabelecimentos.value.unshift({id: 0, nmestabelecimento: 'TODOS'});
                }catch(error){console.error(error);}
                try{
                    const rTipos = await genericPost('/listas/tpdocs', { idep: login.userData.value.idep });
                    lsTpDocs.value = rTipos;
                    lsTpDocs.value.unshift({id: 0, nmtpdoc: 'TODOS'});
                }catch(error){console.error(error);}
            }finally{isLoading.value=false;}
        }

        const lerAnexo = async(obj) => {
            try {
                isLoading.value = true;
                let req;
                if (obj.nmtabela === 'PLA01'){
                    req = await genericPost('/lspla/pla01_anexo_base64', {id: 0, idreferencia: obj.idregistro, strparam1: 'L'});
                } else if(obj.nmtabela === 'PLA06'){
                    req = await genericPost('/lspla/pla06_anexo_base64', {id: 0, idreferencia: obj.idregistro, strparam1: 'L'});
                } else{
                    req = await genericPost('/main/anexo_base64', { id: obj.id });

                }
                if (req){
                    const pdfBase64 = req.anexo;
                    const pdfWindow = window.open("");
                    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+encodeURIComponent(pdfBase64) +"'></iframe>'");
                }else{
                    alert('Laudo indisponível');
                }
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try{
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: (estabelecimentoSelecionado.value ? estabelecimentoSelecionado.value.id : 0),
                    intparam1: (tpdocSelecionado.value ? tpdocSelecionado.value.id : 0),
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const req = await genericPost('/listas/anexos', dados);
                lsDados.value = req;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const openAnalise = (obj) => {
            objSelecionado.value = obj;
            dtValidade.value = obj.dtvalidade;
            obsAnalise.value = null;
            showAnalise.value = true;
        }

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
        }

        const salvarAnalise = async(aprovadoSN) => {
            if (!dtValidade.value){alert('Necessário informar data de validade!'); return;}
            if (!obsAnalise.value){alert('Necessário informar justificativa'); return;}
            try{
                try {
                    const dados = {
                        id: objSelecionado.value.id,
                        dhanalise : strDataHoraAtual(),
                        idloginanalise: login.userData.value.idlogin,
                        iduanalise: login.userData.value.id,
                        obsanalise: obsAnalise.value,
                        homologado: aprovadoSN
                    };
                    const ret = await genericPut('/cad/anexo', dados);
                    if (ret.bolok == 0){
                        alert('Erro... contate o suporte');
                    }else{
                        await lerDados();
                        showAnalise.value = false;
                    }
                } catch (error) {
                    alert(error);
                }
            }finally{
                isLoading.value = false;
            }
        } 

        onMounted(()=>{
            inicializar();
        });

        return{ 
            dtValidade, estabelecimentoSelecionado, errorMessage, isLoading, lsDados, lsEstabelecimentos, lsTpDocs, objSelecionado, obsAnalise, showAnalise,
            closePage, lerAnexo, lerDados, openAnalise, rangeDataChange, salvarAnalise, tpdocSelecionado
        };
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
