<template>
  <div class="form-item">
    <label class="label-caption" for="rt">Responsável Técnico (RT)</label>
    <select :value="rtSelecionado" @change="atualizarRT" id="rt" class="form-control">
      <option v-for="rt in rts" :key="rt.id" :value="rt.id">{{ rt.nmusuario }}</option>
    </select>
  </div>
</template>
  
<script>
export default {
  props: {
    rts: {
      type: Array,
      required: true
    },
    rtSelecionado: {
      type: Number,
      default: null
    }
  },

  methods: {
    atualizarRT(event) {
      this.$emit('update:rtSelecionado', Number(event.target.value));
    }
  }
};
</script>