<template>
    <div style="display: flex; gap: 5px;">
        <div class="form-item" style="flex: 0 0 110px;">
            <label class="label-caption">Data Início</label>
            <input type="date" v-model="dataInicio" @input="emitRange"/>
        </div>

        <div class="form-item" style="flex: 0 0 110px;">
            <label class="label-caption">Data Fim</label>
            <input type="date" v-model="dataFim" input="emitRange"/>
        </div>
        
        <div class="form-item" style="flex: 0 0 110px;">
            <label class="label-caption">Período</label>
            <select v-model="periodo" @change="atualizarPeriodo">
                <option value="anoAtual">Ano atual</option>
                <option value="anoAnterior">Ano anterior</option>
                <option value="mesAtual">Mês atual</option>
                <option value="mesAnterior">Mês anterior</option>
            </select>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default{
    name: "RangeDataSelect",
    emits: ['rangeChange'],
    setup(_, {emit}){
        const dataInicio = ref('');
        const dataFim = ref('');
        const periodo = ref('');

        const getPeriodo = () => {
            const now = new Date();
            let inicio, fim;

            switch(periodo.value){
                case 'anoAtual':
                    inicio = new Date(now.getFullYear(), 0, 1);
                    fim = new Date(now.getFullYear(), 11, 31);
                    break;
                case 'anoAnterior':
                    inicio = new Date(now.getFullYear() -1, 0, 1);
                    fim = new Date(now.getFullYear() -1, 11, 31);
                    break;
                case 'mesAtual':
                    inicio = new Date(now.getFullYear(), now.getMonth(), 1);
                    fim = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                    break;
                case 'mesAnterior':
                    inicio = new Date(now.getFullYear(), now.getMonth() -1, 1);
                    fim = new Date(now.getFullYear(), now.getMonth(), 0);
                    break;
            }

            dataInicio.value = inicio.toISOString().split('T')[0];
            dataFim.value = fim.toISOString().split('T')[0];
        }

        const atualizarPeriodo = () => {
            getPeriodo();
            emitRange();
        }

        const emitRange = () => {
            emit('rangeChange', {dataInicio: dataInicio.value, dataFim: dataFim.value});
        }

        return{dataInicio, dataFim, periodo, atualizarPeriodo, emitRange}
    }
}
</script>