<template>
    <div class="container">
      <div v-if="estabelecimento" class="card">
        <h3>DADOS DO ESTABELECIMENTO</h3>
        <p><strong>NOME/RAZÃO SOCIAL:</strong> {{ estabelecimento.nmestabelecimento }}</p>
        <p><strong>NOME FANTASIA:</strong> {{ estabelecimento.nmfantasia }}</p>
        <p><strong>CPF/CNPJ:</strong> {{ estabelecimento.cpfcnpj }}</p>
        <p><strong>FONE:</strong> {{ estabelecimento.fone }}</p>
        <p><strong>E-MAIL:</strong> {{ estabelecimento.email }}</p>
      </div>
      
      <div v-if="rt" class="card">
        <h3>RESPONSÁVEL TÉCNICO</h3>
        <p><strong>NOME:</strong> {{ rt.nmusuario }}</p>
        <p><strong>CPF:</strong> {{ rt.cpf }}</p>
        <p><strong>FONE:</strong> {{ rt.fone }}</p>
        <p><strong>CELULAR:</strong> {{ rt.celular }}</p>
        <p><strong>E-MAIL:</strong> {{ rt.email }}</p>
        <p><strong>MUNICÍPIO:</strong> {{ rt.nmmunicipio }}/{{ rt.uf }}</p>
        <p><strong>NRO REGISTRO:</strong> {{ rt.registronumero }} <strong>ÓRGÃO EMISSOR:</strong> {{ rt.registroorgao }}</p>
        <button @click="trocarRT" class="button">Trocar RT</button>
      </div>
    </div>
  </template>

<script>
import { reactive } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { getCadastroById } from '@/funcoes/api'; 

export default{
  name: 'RtViewPage',

  mounted(){
    this.lerDados();
  },
  
  methods: {
    trocarRT() {
      window.alert('Funcionalidade em desenvolvimento. Em breve, você poderá trocar o responsável técnico.');
    }
  },

  setup(){
    const estabelecimento = reactive({});
    const rt = reactive({});
    const usuario = usuarioLogado();

    const lerDados = async() => {
      if (usuario.userData){
        try{
          const r_est = await getCadastroById('estabelecimento', usuario.userData.value.idestabelecimento);
          Object.assign(estabelecimento, r_est);
          if (estabelecimento.idrt){
            try{
              const retrt = await getCadastroById('usuario', estabelecimento.idrt);
              Object.assign(rt, retrt);
            }catch (error){console.error('Erro leitura dados RT', error)}
          }
        }catch (error){console.error('Erro leitura dados estabelecimento', error);}
      }     
    };

    return { estabelecimento, rt, lerDados };
  }
};
</script>


<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px);
}

.card h3 {
  margin-top: 0;
  color: #333;
}

.card p {
  margin: 8px 0;
  color: #555;
}

.card p strong {
  color: #000;
}

.button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}
</style>
