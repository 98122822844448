import { reactive } from 'vue';

export const criarTpDoc = () => {
  return reactive({
    id: 0,
    idep: 0,
    cdtpdoc: 0,
    nmtpdoc: '',
    detalhamento: '',
    ativo: 'S',
    ckpadrao: 'S',
    pzpadrao: 0,
    pztipo: 'M',
    pztiponm: '',
    idusuario: 0
  });
};