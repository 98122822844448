<template>
    <div class="form-item">
        <label class="label-caption" for="estabelecimento">Estabelecimento</label>
        <select :value="estabelecimentoSelecionado?.id" @change="atualizarEstabelecimento" id="estabelecimento" class="form-control" v-bind="$attrs">
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmestabelecimento }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        lsDados: {
            type: Array,
            required: true
        },
        estabelecimentoSelecionado: {
            type: Object,
            default: null
        }
    },

    methods: {
        atualizarEstabelecimento(event) {
            const selectedId = Number(event.target.value);
            const selectedEstabelecimento = this.lsDados.find(obj => obj.id === selectedId);
            this.$emit('update:estabelecimentoSelecionado', selectedEstabelecimento);
        }
    }
};
</script>