<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdsim }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmsim">Nome do SIM</label>
                    <input type="text" v-model="nmsim" id="nmsim" required maxlength="80" @input="nmsim = nmsim.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="cnpj">CNPJ</label>
                    <input type="text" class="input-center" v-model="cnpj" id="cnpj" maxlength="18" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item flexible">
                    <label class="label-caption" for="endereco">Endereço</label>
                    <input type="text" v-model="endereco" id="endereco" maxlength="80" @input="endereco = endereco.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="nmendereco">Número</label>
                    <input type="text" class="input-right" v-model="formatedNmEndereco" id="nmendereco" v-number maxlength="7" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="complemento">Complemento</label>
                    <input type="text" v-model="complemento" id="complemento" maxlength="80" @input="complemento = complemento.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="bairro">Bairro</label>
                    <input type="text" v-model="bairro" id="bairro" maxlength="60" @input="bairro = bairro.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 100px;">
                    <label class="label-caption" for="cep">CEP</label>
                    <input type="text" v-model="cep" id="cep" v-cep maxlength="10" />
                </div>
                <MunicipioSelect class="form-item"
                    :municipios="lsMunicipios"
                    v-model:municipioSelecionado="idmunicipio" required />
            </div>

            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="fone">Telefone</label>
                    <input type="text" v-model="fone" id="fone" v-phone maxlength="15" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="email">Email</label>
                    <input type="email" v-model="email" id="email" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="rptitulo">Título Relatórios</label>
                    <input type="text" v-model="rptitulo" id="rptitulo" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="rpsubtitulo">Subtítulo Relatórios</label>
                    <input type="text" v-model="rpsubtitulo" id="rpsubtitulo" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { criarSim } from '@/models/sim';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import MunicipioSelect from '@/components/selects/MunicipioSelect.vue';

export default {
    name: 'SimEditPage',
    components: {MunicipioSelect},
    computed: {
        formatedNmEndereco:{
            get(){return this.formatNumber(this.nmendereco);},
            set(value){this.nmendereco = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props: {idEdicao: {type: Number, default: 0}},

    setup(props, { emit }) {
        const sim = criarSim();
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsMunicipios = ref([]);

        const submitForm = async () => {
            if (sim.idep>0){
                try {
                    const response = await genericPut('/cad/sim', sim);
                    console.log('Success', response);
                    emit('salvo');
                    emit('close');
                } catch (error) {console.error('Error:', error);}
            }else{window.alert('Não foi possível determinar E.P.. Favor logar-se novamente no sistema!');}        
        };

        const cancelForm = () => {emit('close');};

        const ckAtivo = computed({
            get() {return sim.ativo === "S";},
            set(value) {sim.ativo = value ? "S" : "N";}
        });

        const lerDados = async() => {
            try{
                isLoading.value=true;
                // BAIXAR LISTA DE MUNICÍPIOS
                try{
                    const reqMun = await genericPost('/listas/municipios', { idep: login.userData.value.idep, ativo: 'S' });
                    lsMunicipios.value = reqMun;
                }catch (error){console.error('Erro leitura dos dados', error);}

                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('sim', props.idEdicao);
                        Object.assign(sim, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    sim.idep  = login.userData.value.idep;
                    sim.ativo = 'S';
                }
                sim.idusuario = login.userData.value.id;
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ...toRefs(sim), isLoading, lsMunicipios, submitForm, cancelForm, ckAtivo };
    }
};
</script>