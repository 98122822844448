<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome Propduto</th>
                    <th>Unidade</th>
                    <th>Situação</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ obj.cdproduto }}</td>
                    <td>{{ obj.nmproduto }}</td>
                    <td>{{ obj.unidade }}</td>
                    <td>{{ obj.xativo }}</td>
                    <td><div>
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div class="buttons margin-top10">
            <button type="button" @click="novoCadastro()">Novo Cadastro</button>
            <button type="button" @click="closeForm()">Sair</button>
        </div>
    </div>


    <div v-if="modoEdicao">
        <ProdutoEditPage :idAtividade="idAtividade" :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import ProdutoEditPage from '../cadastros/formularios/ProdutoEditPage.vue';

export default{
    name: 'ProdutosListaPage',
    emits: ['close'],
    components:{ProdutoEditPage},
    props:{
        idAtividade: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const modoEdicao = ref(false);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/produtos', {idep: login.userData.value.idep, idatividade: props.idAtividade});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        lerDados();

        return { closeForm, editarCadastro, lerDados, novoCadastro, idEdicao, isLoading, lsDados, modoEdicao };
    }
}
</script>
