<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdestabelecimento }}</div>
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="nrosim">Nro SIM</label>
                    <input type="text" class="input-center" v-model="nrosim" id="nrosim" maxlength="9" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmestabelecimento">Nome do Estabelecimento</label>
                    <input type="text" v-model="nmestabelecimento" id="nmestabelecimento" required maxlength="80" @input="nmestabelecimento = nmestabelecimento.toUpperCase()" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmfantasia">Nome Fantasia</label>
                    <input type="text" v-model="nmfantasia" id="nmfantasia" maxlength="80" @input="nmfantasia = nmfantasia.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="cpfcnpj">CPF/CNPJ</label>
                    <input type="text" class="input-center" v-model="cpfcnpj" id="cpfcnpj" maxlength="18" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item flexible">
                    <label class="label-caption" for="endereco">Endereço</label>
                    <input type="text" v-model="endereco" id="endereco" maxlength="80" @input="endereco = endereco.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="nmendereco">Número</label>
                    <input type="text" class="input-right" v-model="formatedNmEndereco" id="nmendereco" v-number maxlength="8" />
                </div>  
                <div class="form-item">
                    <label class="label-caption" for="complemento">Complemento</label>
                    <input type="text" v-model="complemento" id="complemento" maxlength="80" @input="complemento = complemento.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="bairro">Bairro</label>
                    <input type="text" v-model="bairro" id="bairro" maxlength="60" @input="bairro = bairro.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 100px;">
                    <label class="label-caption" for="cep">CEP</label>
                    <input type="text" v-model="cep" id="cep" v-cep maxlength="10" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="fone">Telefone</label>
                    <input type="text" v-model="fone" id="fone" v-phone maxlength="15" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="fone2">Telefone II</label>
                    <input type="text" v-model="fone2" id="fone2" v-phone maxlength="15" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="ie">Inscrição Estadual</label>
                    <input type="text" class="input-center" v-model="ie" id="ie" maxlength="18" />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="im">Inscrição Municipal</label>
                    <input type="text" class="input-center" v-model="im" id="im" maxlength="18" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="nmcontato">Nome do Contato</label>
                    <input type="text" v-model="nmcontato" id="nmcontato" />
                </div>
                <div class="form-item" style="flex: 0 0 180px;">
                    <label class="label-caption" for="celularcontato">Nro Celular</label>
                    <input type="text" v-model="celularcontato" id="celularcontato" v-phone maxlength="15" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="emailcontato">Email</label>
                    <input type="email" v-model="emailcontato" id="emailcontato" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="email">Email</label>
                    <input type="email" v-model="email" id="email" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="email2">Email Alternativo</label>
                    <input type="email" v-model="email2" id="email2" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption" for="obs">Observações</label>
                    <input type="text" v-model="obs" id="obs" />
                </div>
            </div>

            <div class="form-group  form-row">
                <AtividadeSelect class="form-item"
                    :atividades="lsAtividades"
                    v-model:atividadeSelecionada="idatividade" required />
                <RTSelect class="form-item"
                    :rts="lsRts"
                    v-model:rtSelecionado="idrt" />
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <label>Cadastrado no Sebraetec</label>
                <input type="checkbox" class="input-checkbox" id="cadsebraetec" v-model="ckCadSebraeTec" />
            </div>

            <div class="form-group buttons">
                <label>Dados móveis disponível</label>
                <input type="checkbox" class="input-checkbox" id="ckdadosmoveis" v-model="ckDadosMoveis" />
            </div>

            <div class="form-group buttons">
                <label>Impressora disponível</label>
                <input type="checkbox" class="input-checkbox" id="ckimpressora" v-model="ckImpressora" />
            </div>

            <div class="form-group buttons">
                <label>Internet disponível no local</label>
                <input type="checkbox" class="input-checkbox" id="ckinternet" v-model="ckInternet" />
            </div>

            <div class="form-group buttons margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { criarEstabelecimento } from '@/models/estabelecimento';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import AtividadeSelect from '@/components/selects/AtividadeSelect.vue';
import RTSelect from '@/components/selects/RTSelect.vue';

export default {
    name: 'EstabelecimentoEditPage',
    components: {AtividadeSelect, RTSelect},
    computed: {
        formatedNmEndereco:{
            get(){return this.formatNumber(this.nmendereco);},
            set(value){this.nmendereco = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props: {idEstabelecimento: {type: Number, default: 0}},
    setup(props, { emit }) {
        const estabelecimento = criarEstabelecimento();
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsAtividades = ref([]);
        const lsRts = ref([]);

        const submitForm = async () => {
            if (estabelecimento.idsim>0){
                try {
                    const response = await genericPut('/cad/estabelecimento', estabelecimento);
                    console.log('Success', response);
                    emit('salvo');
                    emit('close');
                } catch (error) {console.error('Error:', error);}
            }else{
                window.alert('Não foi possível determinar o SIM deste estabelecimento. Favor logar-se novamente no sistema!');
            }        
        };

        const cancelForm = () => {emit('close');};

        const ckAtivo = computed({
            get() {return estabelecimento.ativo === "S";},
            set(value) {estabelecimento.ativo = value ? "S" : "N";}
        });

        const ckCadSebraeTec = computed({
            get() {return estabelecimento.cadsebraetec === "S";},
            set(value) {estabelecimento.cadsebraetec = value ? "S" : "N";}
        });

        const ckDadosMoveis = computed({
            get() {return estabelecimento.ckdadosmoveis === "S";},
            set(value) {estabelecimento.ckdadosmoveis = value ? "S" : "N";}
        });

        const ckImpressora = computed({
            get() {return estabelecimento.ckimpressora === "S";},
            set(value) {estabelecimento.ckimpressora = value ? "S" : "N";}
        });

        const ckInternet = computed({
            get() {return estabelecimento.ckinternet === "S";},
            set(value) {estabelecimento.ckinternet = value ? "S" : "N";}
        });

        const lerDados = async() => {
            try{
                isLoading.value=true;
                // BAIXAR LISTA DE ATIVIDADES
                try{
                    const dadosAtividades = {idep: login.userData.value.idep, ativo: 'S'};
                    const reqAtividades = await genericPost('/listas/atividades', dadosAtividades);
                    lsAtividades.value = reqAtividades;
                }catch (error){console.error('Erro leitura dos dados', error);}

                // BAIXAR LISTA DE RTs
                try{
                    const dadosRts = {idep: login.userData.value.idep, tipo: 3, ativo: 'S'};
                    const reqRts = await genericPost('/listas/usuarios', dadosRts);
                    lsRts.value = reqRts;
                }catch (error){console.error('Erro leitura dos dados', error);}

                if (props.idEstabelecimento > 0) {
                    try{
                        const req = await getCadastroById('estabelecimento', props.idEstabelecimento);
                        Object.assign(estabelecimento, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    estabelecimento.idep = login.userData.value.idep;
                    estabelecimento.idsim = login.userData.value.idsim;
                    // PASSEI INICIAR POR TRIGGER DEVIDO REMOÇÃO DO "simAtivo" estabelecimento.idmunicipio = simAtivo.simData.value.idmunicipio;
                    estabelecimento.ativo = 'S';
                }
                estabelecimento.idusuario = login.userData.value.id;
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ...toRefs(estabelecimento), isLoading, lsAtividades, lsRts, submitForm, cancelForm, ckAtivo, ckCadSebraeTec, ckDadosMoveis, ckImpressora, ckInternet };
    }
};
</script>