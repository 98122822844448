<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ id }}</div>
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption" for="dtferiado">Data</label>
                    <input type="date" v-model="dtferiado" id="dtferiado" required />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="nmferiado">Nome/Descrição</label>
                    <input type="text" v-model="nmferiado" id="nmferiado" required maxlength="80" @input="nmferiado = nmferiado.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <TipoFeriadoSelect class="form-item"
                    v-model:tipoSelecionado="tipo" required />
                <MunicipioSelect class="form-item"
                    :municipios="lsMunicipios"
                    v-model:municipioSelecionado="idmunicipio" />
            </div>

            <div class="form-group buttons margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { criarFeriado } from '@/models/feriado';
import { ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import MunicipioSelect from '@/components/selects/MunicipioSelect.vue';
import TipoFeriadoSelect from '@/components/selects/TipoFeriadoSelect.vue';

export default {
    name: 'FeriadoEditPage',
    components: {MunicipioSelect, TipoFeriadoSelect},
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},

    setup(props, { emit }) {
        const feriado = criarFeriado();
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsMunicipios = ref([]);

        const formatDateForInput = (dateStr) => {
            if (!dateStr) return '';
            const [day, month, year] = dateStr.split('/');
            return `${year}-${month}-${day}`;
        };

        const submitForm = async () => {
            if (feriado.idep>0){
                try {
                    const response = await genericPut('/cad/feriado', feriado);
                    console.log('Success', response);
                    emit('salvo');
                    emit('close');
                } catch (error) {console.error('Error:', error);}
            }else{window.alert('Não foi possível determinar E.P.. Favor logar-se novamente no sistema!');}        
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                // BAIXAR LISTA DE MUNICÍPIOS
                try{
                    const reqMun = await genericPost('/listas/municipios', { idep: login.userData.value.idep, ativo: 'S' });
                    lsMunicipios.value = reqMun;
                }catch (error){console.error('Erro leitura dos dados', error);}

                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('feriado', props.idEdicao);
                        Object.assign(feriado, req);
                        feriado.dtferiado = formatDateForInput(feriado.dtferiado);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    feriado.idep  = login.userData.value.idep;
                }
                feriado.idusuario = login.userData.value.id;
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ...toRefs(feriado), isLoading, lsMunicipios, submitForm, cancelForm };
    }
};
</script>