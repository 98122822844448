<template>
    <div>
        <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />

        <div class="margin-top10">
            <table v-if="!errorMessage">
                <thead>
                    <tr>
                        <th>Vistoria</th>
                        <th>Data Vistoria</th>
                        <th>Prazo</th>
                        <th>Responsável</th>
                        <th>Situação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pla13, index) in lsDados" :key="pla13.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                        <td>{{ pla13.xsequencia }}</td>
                        <td>{{ formatDate(pla13.dtvistoria) }}</td>
                        <td>{{ formatDate(pla13.dtprazo) }}</td>
                        <td>{{ pla13.nmresponsavel }}</td>
                        <td>{{ pla13.xstatus }}</td>
                        <td>
                            <div class="row-left">
                                <button class="btn-icon btn-icon--pdf" @click="lerAnexo(pla13.idpla06)"></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';

export default{
    name: 'Pla08PendentesLista',
    components: {MensagemAlerta},
    methods:{
        formatDate(dateStr){
            const[year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        }
    },
    setup(){
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);

        const lerAnexo = async(idPla) => {
            try{
                isLoading.value = true;
                const dados = {id: 0, idreferencia: idPla, strparam1: 'L'};
                const req = await genericPost('/lspla/pla06_anexo_base64', dados);
                const pdfBase64 = req.anexo;
                const pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+
                    encodeURIComponent(pdfBase64) +
                    "'></iframe>'"
                );
            } catch (error) {console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try {
                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idestabelecimento: login.userData.value.idestabelecimento,
                };
                const req = await genericPost('/lspla/pla08_pendentes', dados);
                lsDados.value = req;
                if (lsDados.value.length === 0){errorMessage.value = 'Nenhum Relatório de Ação Corretiva Pendente.'}
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        };

        lerDados();

        return{
            errorMessage, isLoading, lsDados, 
            lerAnexo
        }
    }
}
</script>