export const numberFormatMixin = {
  methods: {
    // Formatação de números inteiros
    formatNumber(value) {
      return value ? parseInt(value, 10).toLocaleString() : '';
    },
    unformatNumber(value) {
      return value.replace(/\D/g, '');
    },

    // Formatação de números decimais
    formatDecimal(value) {
      if (!value) return '';
      const decimalValue = parseFloat(value).toFixed(2);
      return decimalValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    unformatDecimal(value) {
      return value.replace(/[^\d.-]/g, '');
    }
  }
};
