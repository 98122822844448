import { reactive } from 'vue';

export const criarUsuario = () => {
  return reactive({
    id: 0,
    idep: 0,
    idestabelecimento: 0,
    nmusuario: '',
    cpf: '',
    cpfnros: '',
    endereco: '',
    nmendereco: 0,
    complemento: '',
    cep: '',
    bairro: '',
    nmmunicipio: '',
    uf: '',
    fone: '',
    celular: '',
    email: '',
    email2: '',
    registroorgao: '',
    registronumero: 0,
    matriculanumero: 0,
    login: '',
    senha: '',
    dtsenha: '',
    ativo: 'S',
    nivel: 1,
    perfilestabelecimento: 'N',
    perfilgestor: 'N',
    perfilinspetor: 'N',
    perfilrt: 'N',
    perfilsupervisor: 'N',
    idusuario: 0  
  });
};
