<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome Município</th>
                    <th>UF</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ obj.cdmunicipio }}</td>
                    <td>{{ obj.nmmunicipio }}</td>
                    <td>{{ obj.uf }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div class="buttons margin-top10">
            <button type="button" @click="novoCadastro()">Novo Cadastro</button>
            <button type="button" @click="closeForm()">Sair</button>
        </div>
    </div>


    <div v-if="modoEdicao">
        <MunicipioEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import MunicipioEditPage from '../cadastros/formularios/MunicipioEditPage.vue';

export default{
    name: 'MunicipiosListaPage',
    emits: ['close'],
    components:{MunicipioEditPage},

    setup(props, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const modoEdicao = ref(false);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/municipios', {idep: login.userData.value.idep});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        lerDados();

        return { closeForm, editarCadastro, lerDados, novoCadastro, idEdicao, isLoading, lsDados, modoEdicao };
    }
}
</script>
