import { reactive } from 'vue';

export const criarFeriado = () => {
  return reactive({
    id: 0,
    idep: 0,
    idmunicipio: 0,
    dtferiado: '',
    nmferiado: '',
    tipo: 0,
    xtipo: '',
    dhestorno: '',
    iduestorno: 0,
    obsestorno: '',
    idusuario: 0
  });
};