<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <RangeDataSelect @rangeChange="rangeDataChange" />
        <EstabelecimentoSelect :lsDados="lsEstabelecimentos" v-model:estabelecimentoSelecionado="estabelecimentoSelecionado" />
        <button type="button" @click="lerDados">Pesquisar</button>
        <button type="button" @click="closePage">Encerrar</button>
    </div>

    <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
    
    <div class="margin-top10">
        <table>
            <thead><tr>
                <th>Ano/Mês</th>
                <th>Protocolo</th>
                <th>Estabelecimento</th>
                <th>Situacao</th>
                <th>Ações</th>
            </tr></thead>

            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.idregistro" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ obj.anomes }}</td>
                    <td>{{ obj.protocolo }}</td>
                    <td>{{ obj.nmestabelecimento }}</td>
                    <td>{{ obj.xstatus }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--pdf" @click="lerAnexo(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import EstabelecimentoSelect from '../../selects/EstabelecimentoSelect.vue';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import RangeDataSelect from '@/components/selects/RangeDataSelect.vue';

export default{
    name: 'Pla13ListaInspetorPage',
    emits:['close'],
    components:{ EstabelecimentoSelect, MensagemAlerta, RangeDataSelect },
    setup(_, {emit}){
        const errorMessage = ref(null);
        const estabelecimentoSelecionado = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const lsEstabelecimentos = ref([]);
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});

        const closePage = () => {emit('close');}

        const inicializar = async() => {
            try{
                isLoading.value = true;
                try{
                    const rEst = await genericPost('/listas/estabelecimentos', { idep: login.userData.value.idep, idsim: login.userData.value.idsim });
                    lsEstabelecimentos.value = rEst;
                    lsEstabelecimentos.value.unshift({id: 0, nmestabelecimento: 'TODOS'});
                }catch(error){console.error(error);}
            }finally{isLoading.value=false;}
        }

        const lerAnexo = async(obj) => {
            try {
                isLoading.value = true;
                const req = await genericPost('/lspla/pla13_anexo_base64', {id: 0, idreferencia: obj.id, strparam1: 'L'});
                if (req){
                    const pdfBase64 = req.anexo;
                    const pdfWindow = window.open("");
                    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+encodeURIComponent(pdfBase64) +"'></iframe>'");
                }else{
                    alert('Laudo indisponível');
                }
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try{
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: (estabelecimentoSelecionado.value ? estabelecimentoSelecionado.value.id : 0),
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const req = await genericPost('/lspla/pla13', dados);
                lsDados.value = req;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
        }

        onMounted(()=>{inicializar();});

        return{ 
            estabelecimentoSelecionado, errorMessage, isLoading, lsDados, lsEstabelecimentos, 
            closePage, lerAnexo, lerDados, rangeDataChange 
        };
    }
}
</script>