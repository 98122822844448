<template>
    <div class="form-item">
        <label class="label-caption" for="tipoPrazoAMD">Tipo de Prazo</label>
        <select :value="tipoSelecionado" @change="atualizarPrazoAMD" id="tipoPrazoAMD" class="form-control" v-bind="$attrs">
            <option v-for="tipo in tiposPrazoAMD" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        tipoSelecionado: {type: String, default: null}
    },

    data() {
        return{
            tiposPrazoAMD:[
                {id: 'A', descricao: 'ANUAL'},
                {id: 'M', descricao: 'MENSAL'},
                {id: 'D', descricao: 'DIÁRIO'}
            ]
        };
    },

    methods: {
        atualizarPrazoAMD(event) {
            this.$emit('update:tipoSelecionado', event.target.value);
        }
    }
};
</script>