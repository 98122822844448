<template>
    <div class="user-detail">
        <div class="form-row">
            <div class="form-group thinny-field">
                <label class="label-caption">Código:</label>
                <div class="field-label field-label-end" >{{ obj.cdestabelecimento }}</div>
            </div>

            <div class="form-group large-field">
                <label class="label-caption">Nome:</label>
                <div class="field-label">{{ obj.nmestabelecimento }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">CPF/CNPJ:</label>
                <div class="field-label field-label-center">{{ obj.cpfcnpj }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">Status:</label>
                <div class="field-label field-label-center">{{ obj.xstatus }}</div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group medium-field">
                <label class="label-caption">Nome Fantasia:</label>
                <div class="field-label">{{ obj.nmfantasia }}</div>
            </div>

            <div class="form-group">
                <label class="label-caption">Atividade:</label>
                <div class="field-label">{{ obj.nmatividade }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">SIM:</label>
                <div class="field-label">{{ obj.nmsim }}</div>
            </div>
        </div>
  
        <div class="form-row">
            <div class="form-group large-field">
                <label class="label-caption">Contato:</label>
                <div class="field-label">{{ obj.nmcontato }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">Telefone:</label>
                <div class="field-label">{{ obj.fone }}</div>
            </div>
        
            <div class="form-group  small-field">
                <label class="label-caption">Celular:</label>
                <div class="field-label">{{ obj.celular_contato }}</div>
            </div>
        </div>        
  
        <div class="form-row">
            <div class="form-group large-field">
                <label class="label-caption">Nome RT:</label>
                <div class="field-label">{{ obj.rt_nome }}</div>
            </div>

            <div class="form-group small-field">
                <label class="label-caption">Fone:</label>
                <div class="field-label">{{ obj.rt_fone }}</div>
            </div>
        </div>
  
        <div class="buttons">
          <button type="button" @click="$emit('close')">Fechar</button>
        </div>        
    </div>
</template>

<script>

export default {
  name: 'EstabelecimentoViewPage',
  props: {
    obj: Object
  }
};
</script>

<style scoped>

input {
  width: 100%; /* Faz o input ocupar 100% da largura do contêiner */
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Garante que padding e borda sejam considerados no tamanho total do input */
}

/* Estilos do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 90%;
}

.user-detail {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  margin-bottom: 15px;
  flex: 1; /* Faz os campos terem larguras proporcionais */
}

/* Definindo tamanhos diferentes para os campos */
.thinny-field {
  flex: 0 0 5%; /* 5% da linha, não cresce nem encolhe */
}

.small-field {
  flex: 0 0 15%; /* 15% da linha, não cresce nem encolhe */
}

.medium-field {
  flex: 0 0 30%; /* 30% da linha, não cresce nem encolhe */
}

.large-field {
  flex: 1; /* Ocupa o restante do espaço disponível */
}
</style>
