import { reactive } from 'vue';

export const criarEp = () => {
    return reactive({
        id: 0,
        nmep: '',
        cnpj: '',
        endereco: '',
        nmendereco: 0,
        complemento: '',
        bairro: '',
        cep: '',
        idmunicipio: 0,
        fone: '',
        email: '',
        emaildadosproducao: '',
        emailsupervior: '',
        ativo: 'S',
        pathdocs: '',
        pathimagens: '',
        pathlogos: '',
        nmlogo: '',
        rptitulo: '',
        rpsubtitulo: '',
        idusuario: 0
    });
};
