<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando dados...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdatividade }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmatividade">Nome Atividade</label>
                    <input type="text" v-model="nmatividade" id="nmatividade" required maxlength="80" @input="nmatividade = nmatividade.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="pla13subtipo">PLA-??</label>
                    <input type="text" v-model="pla13subtipo" id="pla13subtipo" maxlength="6" @input="pla13subtipo = pla13subtipo.toUpperCase()" />
                </div>
                <div class="form-item">
                    <label class="label-caption" for="obs">Observações</label>
                    <input type="text" v-model="obs" id="obs" maxlength="200" @input="obs = obs.toUpperCase()" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" @click="cancelForm">Cancelar</button>
            </div>            
        </form>
    </div>
</template>
  
<script>
import { criarAtividade } from '@/models/atividade';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById } from '@/funcoes/api';

export default {
    name: 'AtividadeEditPage',
    emits: ['close', 'salvo'],
    props:{
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const atividade = criarAtividade();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const ckAtivo = computed({
            get() {return atividade.ativo === 'S';},
            set(value) {atividade.ativo = value ? 'S' : 'N';}
        });

        const cancelForm = () => {emit('close');}

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    isLoading.value = true;
                    try{
                        const req = await getCadastroById('atividade', props.idEdicao);
                        Object.assign(atividade, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }else{
                atividade.idep = login.userData.value.idep;
                atividade.ativo = 'S';
            }
            atividade.idusuario = login.userData.value.id;
        };

        const submitForm = async() => {
            try{
                const response = await genericPut('/cad/atividade', atividade);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        lerDados();

        return { ...toRefs(atividade), isLoading, ckAtivo, cancelForm, submitForm };
    }
};
</script>