<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="handleSubmit">
            <div class="form-group form-row">
                <div class="form-item">
                    <label class="label-caption">Nome do Documento</label>
                    <input type="text" v-model="docAnexo.nmdoc" required maxlength="80" @input="docAnexo.nmdoc = docAnexo.nmdoc.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption" >Valido Até</label>
                    <input type="date" v-model="docAnexo.dtvalidade" required />
                </div>
            </div>

            <div class="form-group">
                <div class="form-item">
                    <label class="label-caption">Observações</label>
                    <textarea v-model="docAnexo.obspostagem" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>

            <div class="form-group">
                <div class="form-item">
                    <label class="label-caption">Selecionar Arquivo (PDF ou JPG):</label>
                    <input type="file" @change="handleFileUpload" accept=".pdf,.jpg" required />
                </div>
            </div>

            <div class="buttons">
                <button type="submit">Enviar</button>
                <button type="button" class="btn-cancel" @click="closeForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';
import { criarAnexoUpload } from '@/models/anexoUpload';
import { genericPost } from '@/funcoes/api';
import { usuarioLogado } from '@/composables/usuario';

export default {
    name: 'AnexoUpload',
    emits: ['close', 'salvo'],
    props: {idTpDoc: {type: Number, default: 0}},

    setup(props, { emit }) {
        const docAnexo = criarAnexoUpload();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const closeForm = () => {emit('close');}

        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    docAnexo.anexo = reader.result.split(',')[1]; // remove o prefixo do base64
                    docAnexo.tipo  = file.type === 'application/pdf' ? '0' : '1';
                    docAnexo.fname = file.name;
                };
                reader.readAsDataURL(file);
            }
        };

        const handleSubmit = async() => {
            try{
                isLoading.value=true;
                try{
                    docAnexo.idanexogrupo = 1;
                    docAnexo.idep = login.userData.value.idep;
                    docAnexo.idtpdoc = props.idTpDoc;
                    docAnexo.idestabelecimento = login.userData.value.idestabelecimento;
                    docAnexo.idupostagem = login.userData.value.id;
                    await genericPost('/operacoes/anexo_upload', docAnexo);
                    emit('close');
                    emit('salvo');
                }catch (error){console.log(error);}
            }finally{isLoading.value=false;}
        };

        return {
            docAnexo, isLoading,
            closeForm, handleFileUpload, handleSubmit};
    },
};
</script>

<style scoped src="@/assets/css/layout.css"></style>