<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdtpdoc }}</div>
                </div>
                <div class="form-item">
                    <label class="label-caption" for="nmtpdoc">Nome/Descrição</label>
                    <input type="text" v-model="nmtpdoc" id="nmtpdoc" required maxlength="80" @input="nmtpdoc = nmtpdoc.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <TipoPrazoAMDSelect class="form-item" v-model:tipoSelecionado="pztipo" required />
                </div>         
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="pzpadrao">Prazo</label>
                    <input type="text" class="input-right" v-model="formatedPzPadrao" id="pzpadrao" v-number maxlength="5" required />
                </div>       
            </div>

            <div class="form-group">
                <div class="form-item">
                    <label class="label-caption" for="detalhamento">Detalhamento</label>
                    <textarea v-model="detalhamento" id="detalhamento" required maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <label>Documento padrão todas atividades</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckPadrao" />
            </div>

            <div class="form-group buttons margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { criarTpDoc } from '@/models/tpdoc';
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById } from '@/funcoes/api';
import TipoPrazoAMDSelect from '@/components/selects/TipoPrazoAMDSelect.vue';

export default {
    name: 'TpDocEditPage',
    components: {TipoPrazoAMDSelect},
    computed: {
        formatedPzPadrao:{
            get(){return this.formatNumber(this.pzpadrao);},
            set(value){this.pzpadrao = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props: {idEdicao: {type: Number, default: 0}},

    setup(props, { emit }) {
        const tpDoc = criarTpDoc();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const ckAtivo = computed({
            get() {return tpDoc.ativo === 'S';},
            set(value) {tpDoc.ativo = value ? 'S' : 'N';}
        });

        const ckPadrao = computed({
            get() {return tpDoc.ckpadrao === 'S';},
            set(value) {tpDoc.ckpadrao = value ? 'S' : 'N';}
        });

        const submitForm = async () => {
            if (tpDoc.idep>0){
                try {
                    const response = await genericPut('/cad/tpdoc', tpDoc);
                    console.log('Success', response);
                    emit('salvo');
                    emit('close');
                } catch (error) {console.error('Error:', error);}
            }else{window.alert('Não foi possível determinar E.P.. Favor logar-se novamente no sistema!');}        
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('tpdoc', props.idEdicao);
                        Object.assign(tpDoc, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    tpDoc.idep  = login.userData.value.idep;
                }
                tpDoc.idusuario = login.userData.value.id;
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ...toRefs(tpDoc), isLoading, ckAtivo, ckPadrao, submitForm, cancelForm };
    }
};
</script>