import { reactive } from 'vue';

export const criarProduto = () => {
  return reactive({
    id: 0,
    idep: 0,
    cdproduto: 0,
    idatividade: 0,
    nmproduto: '',
    unidade: '',
    ativo: 'S',
    idusuario: 0
  });
};