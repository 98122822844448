<template>
    <div class="form-item">
        <label class="label-caption" for="atividade">Atividade</label>
        <select :value="atividadeSelecionada" @change="atualizarAtividade" id="atividade" class="form-control" v-bind="$attrs">
            <option v-for="atividade in atividades" :key="atividade.id" :value="atividade.id">{{ atividade.nmatividade }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        atividades: {
            type: Array,
            required: true
        },
        atividadeSelecionada: {
            type: Number,
            default: null
        }
    },

    methods: {
        atualizarAtividade(event) {
            this.$emit('update:atividadeSelecionada', Number(event.target.value));
        }
    }
};
</script>