import { reactive } from 'vue';

export const criarMunicipio = () => {
  return reactive({
    id: 0,
    idep: 0,
    cdmunicipio: 0,
    nmmunicipio: '',
    idibge: 0,
    uf: '',
    iduf: 0,
    idusuario: 0
  });
};