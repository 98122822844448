<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando dados...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdproduto }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmproduto">Nome</label>
                    <input type="text" v-model="nmproduto" id="nmproduto" required maxlength="80" @input="nmproduto = nmproduto.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="unidade">Unidade</label>
                    <input type="text" v-model="unidade" id="unidade" maxlength="6" @input="unidade = unidade.toUpperCase()" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" @click="cancelForm">Cancelar</button>
            </div>            
        </form>
    </div>
</template>
  
<script>
import { criarProduto } from '@/models/produto';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById } from '@/funcoes/api';

export default {
    name: 'ProdutoEditPage',
    emits: ['close', 'salvo'],
    props:{
        idAtividade: {type: Number, default: 0},
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const produto = criarProduto();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const ckAtivo = computed({
            get() {return produto.ativo === 'S';},
            set(value) {produto.ativo = value ? 'S' : 'N';}
        });

        const cancelForm = () => {emit('close');}

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    isLoading.value = true;
                    try{
                        const req = await getCadastroById('produto', props.idEdicao);
                        Object.assign(produto, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }else{
                produto.idep = login.userData.value.idep;
                produto.idatividade = props.idAtividade;
            }
            produto.idusuario = login.userData.value.id;
        };

        const submitForm = async() => {
            try{
                const response = await genericPut('/cad/produto', produto);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        lerDados();

        return { ...toRefs(produto), isLoading, ckAtivo, cancelForm, submitForm };
    }
};
</script>