<template>
    <div class="form-item">
        <label class="label-caption" for="sim">SIM</label>
        <select :value="simSelecionado?.id" @change="atualizarSim" id="sim" class="form-control" v-bind="$attrs">
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmsim }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        lsDados: {
            type: Array,
            required: true
        },
        simSelecionado: {
            type: Object,
            default: null
        }
    },

    methods: {
        atualizarSim(event) {
            const selectedId = Number(event.target.value);
            const selectedSim = this.lsDados.find(obj => obj.id === selectedId);
            this.$emit('update:simSelecionado', selectedSim);
        }
    }
};
</script>