<template>
    <div class="dashboard">
        <div class="stats-row">
            <div class="stat-card">
                <h3>Estabelecimentos</h3>
                <h4>{{ stats.ttestabelecimentos }}</h4>
            </div>

            <div class="stat-card">
                <h3>Coletas</h3>
                <h4>{{ stats.ttcoletas }}</h4>
            </div>

            <div class="stat-card">
                <h3>Vistorias</h3>
                <h4>{{ stats.ttvistorias }}</h4>
            </div>
        </div>


        <div class="chart-row">
            <ChartNcMensalPage :data="chartData" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { genericPost } from '@/funcoes/api';
import { usuarioLogado } from '@/composables/usuario';
import ChartNcMensalPage from './ChartNcMensalPage.vue';

export default{
    components: {ChartNcMensalPage},
    setup(){
        const login = usuarioLogado();
        const stats = ref({
            ttestabelecimentos: 0,
            ttcoletas: 0,
            ttvistorias:0,
        });
        const chartData = ref([]);

        const loadStats = async () => {
            try{
                const response = await genericPost('/dados/resumo_geral', {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                });
                stats.value = response[0];
            }catch(error){console.error('Erro ao carregar dados de estatísticas:', error);}
        };

        const loadChartData = async () => {
            const now = new Date();
            let inicio, fim;
            try{
                inicio = new Date(now.getFullYear(), now.getMonth() -11, 1);
                fim = new Date(now.getFullYear(), now.getMonth() + 1, 0);

                const response = await genericPost('/dados/ttnc_mensal', {
                    idep: login.userData.value.idep, 
                    idsim: login.userData.value.idsim,
                    datainicial: inicio.toISOString().split('T')[0], 
                    datafinal: fim.toISOString().split('T')[0]
                });
                chartData.value = response.map(item => ({
                    x: `${item.ano}-${String(item.mes).padStart(2, '0')}`,
                    y: item.qtdd,
                }));
            }catch (error){console.error('Erro ao carregar dados do gráfico:', error);}
        };

        onMounted(()=>{
            loadStats();
            loadChartData();
        });

        return{stats, chartData};
    }
}
</script>