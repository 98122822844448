<template>
<h3>TABELA DE GESTORES</h3>

<div v-if="isLoading" class="loading-overlay"><p>Carregando</p></div>

<div v-if="!modoEdicao && !modoVisualizacao" class="margin-top10">
    <table>
        <thead>
            <th>Código</th>
            <th>Nome Gestor</th>
            <th>Celular</th>
            <th>Ações</th>
        </thead>
        <tbody>
            <tr v-for="(gestor, index) in lsGestores" :key="gestor.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                <td>{{ gestor.id }}</td>
                <td>{{ gestor.nmusuario }}</td>
                <td>{{ gestor.celular }}</td>
                <td><div class="row-left">
                    <button class="btn-icon btn-icon--credenciais" @click="visualizarCadastro(gestor)"></button>
                    <button class="btn-icon btn-icon--edit" @click="editarCadastro(gestor.id)"></button>
                </div></td>
            </tr>
        </tbody>
    </table>

    <div class="buttons margin-top10">
        <button type="button" @click="novoCadastro()">INCLUIR</button>
        <button type="button" @click="closePage()">FECHAR</button>
    </div>
</div>

<div v-if="modoEdicao" class="margin-top10">
    <UsuarioEditPage 
        :idEdicao="idEdicao" :idEstabelecimento="Number(estabelecimentoView.id)" :perfilEstabelecimento="true"
        @close="modoEdicao = false" 
        @salvo="lerDados" />
</div>

<div v-if="modoVisualizacao" class="margin-top10">
    <UsuarioViewPage :user="selectedUser" @close="modoVisualizacao = false" />
</div>
</template>

<script>
import { genericPost } from '@/funcoes/api';
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import UsuarioEditPage from '../cadastros/formularios/UsuarioEditPage.vue';
import UsuarioViewPage from '../cadastros/views/UsuarioViewPage.vue';

export default{
    name: 'GestoresListaPage',
    components: {UsuarioEditPage, UsuarioViewPage},
    emits: ['close'],
    props: {estabelecimentoView: Object},
    
    setup(props, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsGestores = ref([]);
        const modoEdicao = ref(false);
        const modoVisualizacao = ref(false);
        const selectedUser = ref(null);

        const closePage = () => {
            emit('close');
        }

        const editarCadastro = (id) => {
            idEdicao.value = id;
            modoEdicao.value = true;
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/usuarios', {idep: login.userData.value.idep, idestabelecimento: props.estabelecimentoView.id, tipo: 2});
                lsGestores.value = req;
            } catch (error){console.error('Erro leitura dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const visualizarCadastro = (obj) => {
            selectedUser.value = obj;
            modoVisualizacao.value = true;
        }

        lerDados();

        return{ closePage, editarCadastro, lerDados, novoCadastro, visualizarCadastro, idEdicao, isLoading, lsGestores, modoEdicao, modoVisualizacao, selectedUser };
    }
}
</script>