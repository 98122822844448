<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <SimSelect :lsDados="lsSim" v-model:simSelecionado="simSelecionado" />
        <button type="button" @click="lerDados">Pesquisar</button>
    </div>

    <div class="margin-top10">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nro SIM</th>
                    <th>Nome/Razão Social</th>
                    <th>SIM</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(estab, index) in lsDados" :key="estab.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ estab.cdestabelecimento }}</td>
                    <td>{{ estab.nrosim }}</td>
                    <td>{{ estab.nmestabelecimento }}</td>
                    <td>{{ estab.nmsim }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import SimSelect from '../selects/SimSelect.vue';

export default{
    name: 'EstabelecimentosSupervisorPage',
    components:{SimSelect},
    setup(){
        const login = usuarioLogado();
        const lsDados = ref([]);
        const lsSim = ref([]);
        const isLoading = ref(false);
        const simSelecionado = ref(null);

        const inicializar = async() => {
            try{
                isLoading.value = true;
                try{
                    const rSim  = await genericPost('/listas/sims', {idep: login.userData.value.idep});
                    lsSim.value = rSim;
                    lsSim.value.unshift({ id: 0, nmsim: 'TODOS' });
                }catch(error){console.error(error);}
            }finally{isLoading.value = false;}
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const dados = {idep: login.userData.value.idep, idsim: (simSelecionado.value ? simSelecionado.value.id : 0)};
                const req = await genericPost('/listas/estabelecimentos', dados);
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        inicializar();

        return { isLoading, lsDados, lsSim, simSelecionado, lerDados };
    }
}
</script>
