export default {
    beforeMount(el) {
        el.addEventListener('input', function (e) {
            let value = e.target.value.replace(/\D/g, '');
    
            if (value.length > 2 && value.length <= 5) {
                value = value.replace(/(\d{2})(\d)/, '$1.$2');
            } else if (value.length > 5 && value.length <= 8) {
                value = value.replace(/(\d{2})(\d{3})(\d)/, '$1.$2.$3');
            } else if (value.length > 8 && value.length <= 12) {
                value = value.replace(/(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
            } else if (value.length > 12) {
                value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/, '$1.$2.$3/$4-$5');
            }
    
            if (e.target.value !== value) {
                e.target.value = value;
                const event = new Event('input', { bubbles: true });
                el.dispatchEvent(event);
            }
        });
    }
};