<template>
<div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

<div v-if="!modoEdicao && !modoEditarGestores && !modoVisualizar">
    <table>
        <thead>
            <tr>
                <th>Código</th>
                <th>Nro SIM</th>
                <th>Nome/Razão Social</th>
                <th>SIM</th>
                <th>Ações</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(estab, index) in lsDados" :key="estab.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                <td>{{ estab.cdestabelecimento }}</td>
                <td>{{ estab.nrosim }}</td>
                <td>{{ estab.nmestabelecimento }}</td>
                <td>{{ estab.nmsim }}</td>
                <td><div class="row-left">
                    <button class="btn-icon btn-icon--credenciais" @click="visualizarCadastro(estab)"></button>
                    <button class="btn-icon btn-icon--edit" @click="editarCadastro(estab.id)"></button>
                    <button class="btn-icon btn-icon--gestores" @click="editarGestores(estab)"></button>
                </div></td>
            </tr>
        </tbody>
    </table>

    <div class="buttons margin-top10">
        <button type="button" @click="novoCadastro()">Novo Cadastro</button>
        <button type="button" @click="closeForm()">Retornar</button>
    </div>
</div>
  

<div v-if="modoEdicao">
    <EstabelecimentoEditPage :idEstabelecimento="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
</div>


<div v-if="modoEditarGestores" class="margin-top10">
    <GestoresListaPage :estabelecimentoView="selectedObj" @close="modoEditarGestores = false" />
</div>


<div v-if="modoVisualizar" class="margin-top10">
    <EstabelecimentoViewPage :obj="selectedObj" @close="modoVisualizar = false" />
</div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import EstabelecimentoEditPage from '../cadastros/formularios/EstabelecimentoEditPage.vue';
import EstabelecimentoViewPage from '../cadastros/views/EstabelecimentoViewPage.vue';
import GestoresListaPage from './GestoresListaPage.vue';

export default{
    name: 'EstabelecimentosInspetorPage',
    emits:['close'],
    components:{
        EstabelecimentoEditPage,
        EstabelecimentoViewPage,
        GestoresListaPage
    },

    methods: {
        editarCadastro(idCadastro){
            this.idEdicao = idCadastro;
            this.modoEdicao = true;  
        },
        editarGestores(obj){
            this.selectedObj = obj;
            this.modoEditarGestores = true;
        },
        novoCadastro(){
            this.idEdicao = 0;
            this.modoEdicao = true;  
        },
        visualizarCadastro(obj){
            this.selectedObj = obj;
            this.modoVisualizar = true;
        }
    },

    setup(_, {emit}){
        const login = usuarioLogado();
        const modoEdicao = ref(false);
        const modoEditarGestores = ref(false);
        const modoVisualizar = ref(false);
        const idEdicao = ref(0);
        const lsDados = ref([]);
        const isLoading = ref(false);
        const selectedObj = ref(null);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost(
                    '/listas/estabelecimentos', 
                    {idep: login.userData.value.idep, idsim: login.userData.value.idsim});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        lerDados();

        return { closeForm, lerDados, idEdicao, isLoading, lsDados, modoEdicao, modoEditarGestores, modoVisualizar, selectedObj };
    }
}
</script>
