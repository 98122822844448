<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao && !modoVisualizacao">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Estabelecimento</th>
                    <th>Celular</th>
                    <th>Ativo</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ obj.id }}</td>
                    <td>{{ obj.nmusuario }}</td>
                    <td>{{ obj.nmestabelecimento }}</td>
                    <td>{{ obj.celular }}</td>
                    <td>{{ obj.ativo }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--credenciais" @click="openView(obj)"></button>
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(obj.id)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div class="buttons margin-top10">
            <button type="button" @click="novoCadastro()">Novo Cadastro</button>
            <button type="button" @click="closeForm()">Sair</button>
        </div>
    </div>

    <div v-if="modoVisualizacao">
        <UsuarioViewPage :user="selectedUser" @close="modoVisualizacao = false" />
    </div>

    <div v-if="modoEdicao">
        <UsuarioEditPage :idEdicao="idEdicao" :perfilEstabelecimento=true @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import UsuarioEditPage from '@/components/cadastros/formularios/UsuarioEditPage.vue';
import UsuarioViewPage from '@/components/cadastros/views/UsuarioViewPage.vue';

export default{
    name: 'UsuariosAdminListaPage',
    emits: ['close'],
    components: {UsuarioEditPage, UsuarioViewPage},
    setup(_, { emit }){
        const login = usuarioLogado();
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const modoVisualizacao = ref(false);
        const selectedUser = ref(null);

        const closeForm = () => {emit('close');}

        const editarCadastro = (idRt) =>{
            idEdicao.value = idRt;
            modoEdicao.value = true;
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const dados = {idep: login.userData.value.idep, tipo: 2};
                const req = await genericPost('/listas/usuarios', dados);
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const openView = (obj) => {
            selectedUser.value = obj;
            modoVisualizacao.value = true;
        }

        lerDados();

        return { closeForm, editarCadastro, lerDados, novoCadastro, openView, idEdicao, isLoading, lsDados, modoEdicao, modoVisualizacao, selectedUser };
    }
}
</script>