import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            userData: JSON.parse(sessionStorage.getItem('userData')) || {
                id: 0,
                idlogin: 0,
                idep: 0,
                nmep: "",
                nmusuario: "",
                cpf: "",
                celular: "",
                fone: "",
                email: "",
                login: "",
                senha: "",
                dtsenha: "",
                perfilgestor: "",
                perfilsupervisor: "",
                perfilinspetor: "",
                perfilestabelecimento: "",
                perfilrt: "",
                nivel: 0,
                appname: "",
                appver: "",
                idestabelecimento: 0,
                nmestabelecimento: "",
                idsim: 0,
                nmsim: ""
            }
        };
    },
    mutations: {
        clearUserData(state) {
            state.userData = null;
            sessionStorage.removeItem('userData');
        },
        setUserData(state, newData) {
            state.userData = newData;
            sessionStorage.setItem('userData', JSON.stringify(newData));
        },
        updateLoginName(state, loginName){
            state.userData.login = loginName;
            sessionStorage.setItem('userData', JSON.stringify(state.userData));
        },
        updateSimFields(state, { idsim, nmsim }){
            state.userData.idsim = idsim;
            state.userData.nmsim = nmsim;
            sessionStorage.setItem('userData', JSON.stringify(state.userData));
        },
    },
    actions: {
        clearSessionData({ commit }) {
            commit('clearUserData');
        },
        updateUserData({ commit }, newData) {
           commit('setUserData', newData);
        },
        updateLoginName({ commit }, loginName){
            commit('updateLoginName', loginName);

        },
        updateSimFields({ commit }, simFields) {
            commit('updateSimFields', simFields);
        }
    },
    getters: {
        getUserData: state => state.userData
    }
});

export default store;
