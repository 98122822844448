<template>
    <div class="form-item">
        <label class="label-caption" for="tipoFeriado">Tipo de Feriado</label>
        <select :value="tipoSelecionado" @change="atualizarTipoFeriado" id="tipoFeriado" class="form-control" v-bind="$attrs">
            <option v-for="tipo in tiposFeriado" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        tipoSelecionado: {type: Number, default: null}
    },

    data() {
        return{
            tiposFeriado:[
                {id: 1, descricao: 'NACIONAL'},
                {id: 2, descricao: 'ESTADUAL'},
                {id: 3, descricao: 'MUNICIPAL'}
            ]
        };
    },

    methods: {
        atualizarTipoFeriado(event) {
            this.$emit('update:tipoSelecionado', Number(event.target.value));
        }
    }
};
</script>