import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { FontAwesomeIcon } from './plugins/fontawesome';
import cepMask from './diretives/cepMask';
import cnpjMask from './diretives/cnpjMask';
import cpfMask from './diretives/cpfMask';
import numberMask from './diretives/numberMask';
import phoneMask from './diretives/phoneMask';

const app = createApp(App);

app.directive('cep', cepMask);
app.directive('cnpj', cnpjMask);
app.directive('cpf', cpfMask);
app.directive('number', numberMask);
app.directive('phone', phoneMask);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
