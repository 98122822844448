<template>
    <div>
        <div v-if="!userData || !userData.id"><LoginPage/></div>
        <div v-else-if="userData.perfilestabelecimento=='S'"><MainEstabelecimento/></div>
        <div v-else-if="userData.perfilgestor=='S'"><MainGestor/></div>
        <div v-else-if="userData.perfilinspetor=='S'"><MainInspetor/></div>
        <div v-else-if="userData.perfilrt=='S'"><MainRT/></div>
        <div v-else-if="userData.perfilsupervisor=='S'"><MainSupervisor/></div>
        <div v-else><MainIndefinido/></div>
    </div>
</template>


<script>
import { computed } from 'vue'
import { useStore } from "vuex"
import LoginPage from '@/components/main/LoginPage.vue'
import MainEstabelecimento from '@/components/main/MainEstabelecimento.vue'
import MainGestor from '@/components/main/MainGestor.vue'
import MainIndefinido from '@/components/main/MainIndefinido.vue'
import MainInspetor from '@/components/main/MainInspetor.vue'
import MainRT from '@/components/main/MainRT.vue'
import MainSupervisor from '@/components/main/MainSupervisor.vue'

export default {
    name: 'App',
    components: {
        LoginPage,
        MainEstabelecimento,
        MainGestor,
        MainIndefinido,
        MainInspetor,
        MainRT,
        MainSupervisor
    },
    created(){},
    methods: {
        restoreSessionData() {
            const userData = sessionStorage.getItem('userData');
            if (userData){
                this.$store.dispatch('updateUserData', JSON.parse(userData));
            }
        }
    },
    setup(){
        const store = useStore();
        const userData = computed(() => store.getters.getUserData);
        return{userData};
    }
}
</script>

<style src="@/assets/css/layout.css"></style>
<style src="@/assets/css/main.css"></style>

<style>
*{
    margin: 0;
    padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: small;
    text-align: center;
    margin: 0;
    padding: 0;
}
h3{
    color: navy;
}
label{
    padding: 30;
    margin-bottom: 5px;
    color: navy;
    font-size: 0.9em;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    font-weight: bold;
}
tr:hover{
    background-color: #a3c4c2;;
}
.even-row {
    background-color: #f9f9f9;
}
.odd-row {
    background-color: #ffffff;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #000;
}

.modal-image {
    width: 100%;
    height: auto;
  }

/************************************** */
/* ESTILOS DO PROJETO REVISADOS PADRÕES */

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Espaçamento entre os itens, ajustável conforme necessário */
}

.buttons-icon-container{
    display: flex;  
    gap: 30px;
}

button {
    width: 160px;
    padding: 5px 0px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type="cancel"] {
    background-color: #ff0000;
    color: #fff;
}

button[type="cancel"]:hover {
    background-color: #b30000;
}

button[type="submit"] {
    background-color: #007bff;
    color: #fff;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

button[type="button"] {
    background-color: #ccc;
    color: #000;
}

button[type="button"]:hover {
    background-color: #bbb;
}

.field-label {
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    min-height: 16px;
    padding: 5px;
    white-space: pre-line;
}
.field-label-start {
    justify-content: flex-start;
}
.field-label-center {
    justify-content: center;
}
.field-label-end {
    justify-content: flex-end;
}

.form-edit-page {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 10px;
    gap: 10px;
}

.form-item {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;  
    padding: 0px;
    width: 0px;
}

.icon-button:hover .icon {
    filter: brightness(1.2);
}

.icon {
    padding: 2px;
    width: 16px;
    height: 16px;
}

input {
    width: 100%; /* Faz o input ocupar 100% da largura do contêiner */
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Garante que padding e borda sejam considerados no tamanho total do input */
    min-height: 16px;
}
.input-center{
    text-align: center;
}
.input-right{
    text-align: right;
}
.input-checkbox{
    width: auto;
}

input[type="date"] {
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
}

.label-caption{
    display: block;
    font-weight: bold;
    text-align: left;
    margin-bottom: 2px;
}

.margin-top10{
    margin-top: 10px;
}

select {
    width: 100%; /* Faz o input ocupar 100% da largura do contêiner */
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

textarea {
    width: 100%; /* Faz o input ocupar 100% da largura do contêiner */
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Garante que padding e borda sejam considerados no tamanho total do input */
}
</style>
