<template>
    <div class="alert">
        <h3>USUÁRIO RT</h3>
    </div>

    <button @click="resetLogin">SAIR DO APLICATIVO</button><br><br>

</template>

<script>
import { computed } from 'vue';
import { useStore } from "vuex";

export default{
    name: 'MainRT',
    setup(){
        const store = useStore();
        const userData = computed(() => store.getters.getUserData);
        const resetLogin = () => {
            store.dispatch('clearSessionData');
        }
        return{ resetLogin, userData };
    }
}
</script>

<style scoped>
.alert {
  padding: 15px;
  background-color: #c21404;
  color: white;
  margin-bottom: 25px;
}
h3{
    color: aliceblue;
}
</style>