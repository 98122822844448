<template>
    <div>
        <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <RangeDataSelect @rangeChange="rangeDataChange" />
            <button type="button" @click="lerDados">Pesquisar</button>
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th>Protocolo</th>
                        <th>Vistoria</th>
                        <th>Responsável</th>
                        <th>Fiscal</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pla06, index) in lsDados" :key="pla06.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                        <td>{{ pla06.xsequencia }}</td>
                        <td>{{ formatDate(pla06.dtvistoria) }}</td>
                        <td>{{ pla06.nmresponsavel }}</td>
                        <td>{{ pla06.nmvistoriador }}</td>
                        <td><div class="row-left">
                            <button class="btn-icon btn-icon--pdf" @click="mostrarLaudo(pla06.id)"></button>
                            <button class="btn-icon btn-icon--jpg" @click="mostrarFotos(pla06.id)"></button>
                        </div></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ModalImagem v-model:show="showImageModal">
            <div v-if="currentImage">
                <img :src="`data:image/jpeg;base64,${currentImage.fname}`" :alt="currentImage.xtipo" class="modal-image">
                <button @click="nextImage">Próxima Imagem</button>
            </div>
        </ModalImagem>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import ModalImagem from '@/components/utils/ModalImagem.vue';
import RangeDataSelect from '@/components/selects/RangeDataSelect.vue';

export default{
    name: 'Pla06Lista',
    components: {MensagemAlerta, ModalImagem, RangeDataSelect},
    methods: {
        formatDate(dateStr){
            const[year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        },      

        mostrarFotos(id) {this.listarImagens(id);},

        mostrarLaudo(idPla) {this.lerAnexo(idPla);}
    },

    setup(){
        const currentImageIndex = ref(0);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const lsImagens = ref([]);
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});
        const showImageModal = ref(false);

        const getImagem = async(idAnexo) => {
            try{
                const dados = {id: idAnexo, strparam1: '1'};
                const req = await genericPost('/lspla/pla06_anexo_base64', dados);
                return req.anexo;
            }catch (error){console.error('Erro leitura dos dados', error);}
        };

        const lerAnexo = async(idPla) => {
            try{
                isLoading.value = true;
                const dados = {id: 0, idreferencia: idPla, strparam1: 'L'};
                const req = await genericPost('/lspla/pla06_anexo_base64', dados);
                const pdfBase64 = req.anexo;
                const pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+
                    encodeURIComponent(pdfBase64) +
                    "'></iframe>'"
                );
            } catch (error) {console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try {
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: login.userData.value.idestabelecimento,
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const req = await genericPost('/lspla/pla06', dados);
                lsDados.value = req;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        };

        const listarImagens = async(idPla) => {
            try{
                isLoading.value = true;
                const dados = {id: idPla, tipo: 1};
                const req = await genericPost('lspla/pla06_anexos', dados);
                if (req.length == 0){
                    window.alert('Vistoria não possui imagens anexadas');
                    return;
                }
                for (const img of req) {
                    img.fname = await getImagem(img.id); // Fetch base64 string and store it in fname
                }
                lsImagens.value = req;
                currentImageIndex.value = 0;
                showImageModal.value = true;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const nextImage = () => {
            if (currentImageIndex.value < lsImagens.value.length - 1){
                currentImageIndex.value++;
            }else{
                showImageModal.value = false;
            }
        }

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
            console.debug(periodoSelecionado.value);
        }

        const currentImage = computed(() => lsImagens.value[currentImageIndex.value]);

        return { lerAnexo, lerDados, listarImagens, lsDados, showImageModal, nextImage, rangeDataChange, currentImage, errorMessage, isLoading, periodoSelecionado };
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>