<template>
    <div class="layout">
      <aside class="sidebar" :class="{ collapsed: isCollapsed }">
        <ul>          
          <li @click="toggleMenu">
            <img :src="menuIcon" alt="Toggle Menu" />
            <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
          </li>

          <li @click="setComponenteAtivo('Pla06Lista', 'VISTORIAS')">
            <img src="@/assets/icons/menuVistorias.png" />
            <span>Tabela de Vistorias</span>
          </li>
          <li @click="setComponenteAtivo('Pla01Lista', 'COLETAS')">
            <img src="@/assets/icons/menuColetas.png" />
            <span>Tabela de Coletas</span>
          </li>
          <li @click="setComponenteAtivo('Pla13Lista', 'PLANILHAS DE PRODUÇÃO')">
            <img src="@/assets/icons/menuProducao.png" />
            <span>Dados de Produção</span>
          </li>
          <li @click="setComponenteAtivo('Pla08PendentesLista', 'R.A.C. PENDENTES')">
            <img src="@/assets/icons/menuRacPendentes.png" />
            <span>RAC Pendentes</span>
          </li>
          <li @click="setComponenteAtivo('Pla08Lista', 'HISTÓRICO DE R.A.C.')">
            <img src="@/assets/icons/menuHistory.png" />
            <span>Histórico de RAC</span>
          </li>
          <li @click="setComponenteAtivo('EstabDocsListaPage', 'DOCUMENTAÇÕES')">
            <img src="@/assets/icons/menuDocumentos.png" />
            <span>Análise de Documentação</span>
          </li>
          <li @click="setComponenteAtivo('RtViewPage', 'RESPONSÁVEL TÉCNICO')">
            <img src="@/assets/icons/menuRt.png" />
            <span>Responsável Técnico</span>
          </li>
          <li @click="setComponenteAtivo('UsuarioEditPage', 'EDIÇÃO DE DADOS')">
            <img src="@/assets/icons/menuEditUser.png" />
            <span>Alteração de Cadastro</span>
          </li>
          <li @click="setComponenteAtivo('AlterarLoginPage', 'ALTERAÇÃO DE LOGIN E SENHA')">
            <img src="@/assets/icons/menuPass.png" />
            <span>Redefinir Login e Senha</span>
          </li>
          <li class="logout" @click="confirmLogout">
            <img src="@/assets/icons/menuLogout.png" />
            <span>Desconectar</span>
          </li>
        </ul>
      </aside>
      <main class="content">
        <header class="header">
          <div class="header-title">{{ nmTitulo ? nmTitulo : 'ESTABELECIMENTO' }}</div>
          <div class="user-info">
            <div class="user-name">{{ userData.nmusuario }}</div>
            <div class="establishment-name">{{ userData.nmestabelecimento }}</div>
            <div class="establishment-name">Tempo restante do token: {{ timeRemaining }}</div>
          </div>
        </header>
        <div class="main-content">
          <slot>
            <div v-if="nmComponenteAtivo=='AlterarLoginPage'"><AlterarLoginPage @close="setComponenteAtivo('', '')"/></div>
            <div v-else-if="nmComponenteAtivo=='EstabDocsListaPage'"><EstabDocsListaPage @close="setComponenteAtivo('', '')"/></div>
            <div v-else-if="nmComponenteAtivo=='Pla01Lista'"><Pla01Lista/></div>
            <div v-else-if="nmComponenteAtivo=='Pla06Lista'"><Pla06Lista/></div>
            <div v-else-if="nmComponenteAtivo=='Pla08Lista'"><Pla08Lista/></div>
            <div v-else-if="nmComponenteAtivo=='Pla08PendentesLista'"><Pla08PendentesLista/></div>
            <div v-else-if="nmComponenteAtivo=='Pla13Lista'"><Pla13Lista/></div>
            <div v-else-if="nmComponenteAtivo=='RtViewPage'"><RtViewPage/></div>
            <div v-else-if="nmComponenteAtivo=='UsuarioEditPage'"><UsuarioEditPage :idEdicao="userData.id" @close="setComponenteAtivo('', '')"/></div>
            <div v-else></div>
          </slot>
        </div>
      </main>
    </div>
</template>
  
<script>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useStore } from "vuex";
import AlterarLoginPage from '@/components/cadastros/AlterarLoginPage.vue';
import EstabDocsListaPage from '../operacoes/EstabDocsListaPage.vue';
import Pla01Lista from '@/components/planilhas/pla01/Pla01Lista.vue';
import Pla06Lista from '@/components/planilhas/pla06/Pla06Lista.vue';
import Pla08Lista from '@/components/planilhas/pla08/Pla08Lista.vue';
import Pla08PendentesLista from '@/components/planilhas/pla08/Pla08PendentesLista.vue';
import Pla13Lista from '@/components/planilhas/pla13/Pla13Lista.vue';
import RtViewPage from '@/components/cadastros/views/RtViewPage.vue';
import UsuarioEditPage from '@/components/cadastros/formularios/UsuarioEditPage.vue';

export default {
    name: 'MainEstabelecimento',
    components:{
      AlterarLoginPage,
      EstabDocsListaPage,
      Pla01Lista,
      Pla06Lista,
      Pla08Lista,
      Pla08PendentesLista,
      Pla13Lista,
      RtViewPage,
      UsuarioEditPage
    },
    computed: {
      menuIcon() {
        return this.isCollapsed
          ? require('@/assets/icons/menuExpand.png')
          : require('@/assets/icons/menuCollapse.png');
      }
    },
    setup(){
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const store = useStore();
        const timeRemaining = ref(null);
        const userData = computed(() => store.getters.getUserData);
        let timer = null;

        const confirmLogout = () => {resetLogin();}
        
        const resetLogin = () => {store.dispatch('clearSessionData');}

        const setComponenteAtivo = (nome, titulo) => {
          nmComponenteAtivo.value = nome;
          nmTitulo.value = titulo;
        }

        const toggleMenu = () => {isCollapsed.value = !isCollapsed.value;}

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        timeRemaining.value = `${hours}h ${minutes}m ${seconds}s`;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });

        return{ isCollapsed, nmComponenteAtivo, nmTitulo, timeRemaining, userData, confirmLogout, setComponenteAtivo, toggleMenu };
    }
};
</script>