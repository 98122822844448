import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const apiProtocol = window.location.protocol === 'https:' ? 'https' : 'http';
// const apiHost     = '127.0.0.1';
const apiHost     = 'sgsim.ddns.net'; // PRODUÇÃO CONTATO - NECESSÁRIO USO DE DDNS PARA HABILITAR SSL
// const apiHost     = '192.99.28.175'; // HOMOLOGAÇÃO BH SERVER
const apiPort     = '44603';

const apiClient = axios.create({
    baseURL: `${apiProtocol}://${apiHost}:${apiPort}`,
    headers: {
        'Content-Type': 'application/json',
    },
});


// HELPER DE TOKEN
const getToken = () => localStorage.getItem('jwtToken');
const removeToken = () => localStorage.removeItem('jwtToken');
const setToken = (token) => localStorage.setItem('jwtToken', token);

// VERIFICAR VALIDADE DO TOKEN
const isTokenValid = () => {
    const token = getToken();
    if (!token) return false;

    try{
        const decodedToken = jwtDecode(token);
        const now = Date.now();
        return decodedToken.exp * 1000 > now;
    } catch(error){
        console.error('Erro ao decodificar o token:', error);
        return false;
    }
};

apiClient.interceptors.request.use((config) => {
    if (config.skipAuth) return config;

    if (!isTokenValid()){
        /// quando vier de loginAPI deve ignorar validação
        removeToken();
        // TODO: REDIRECIONAR PARA TELA DE LOGIN::: EXEMPLO::: window.location.href = '/login';
        return Promise.reject(new Error('Token expirado'));
    }

    const token = getToken();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));


export const genericGet = async (endpoint, params = {}) => {
    const response = await apiClient.get(endpoint, {params: params});
    return response.data;
};


export const genericPost = async (endpoint, dados) => {
    const response = await apiClient.post(endpoint, dados);
    return response.data;
};


export const genericPut = async (endpoint, dados) => {
    const response = await apiClient.put(endpoint, dados);
    return response.data;
};


export const genericReport = async (dados) => {
    const response = await apiClient.post('/main/relatorio', dados, {responseType: 'blob'});
    return response.data;
};



export const getCadastroById = async (nmcadastro, id) => {
    const response = await apiClient.get(`/cad/${ nmcadastro }`, { params: { id } });
    return response.data;
};


export const loginAPI = async (userName, passWord, dados = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        'jwtusername': userName,
        'jwtpassword': passWord
    };
    removeToken(); // GARANTIR NÃO TER MAIS TOKEN ATIVO

    try{
        const response = await apiClient.post('/login', dados, { headers, skipAuth: true, });
        if (response.data.token){
            setToken(response.data.token);
            return true;
      }else{return false;}
    } catch (error){
        console.error('Erro durante o login:', error);
        return false;
    }
};

export const loginDisponivel = async(idCadastro, loginName) => {
    try{
        const resp = await apiClient.post('/cad/login_disponivel', { id: idCadastro, nmpesquisar: loginName });
        return (resp.data.bolok == 1) ? true : false;
    }catch{
        return false;
    }
}


export const logoutAPI = () => {
    removeToken();
    // TODO: REDIRECIONAR PARA TELA DE LOGIN::: EXEMPLO::: window.location.href = '/login';
}