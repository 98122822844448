<template>
    <div>
        <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <RangeDataSelect @rangeChange="rangeDataChange" />
            <EstabelecimentoSelect :lsDados="lsEstabelecimentos" v-model:estabelecimentoSelecionado="estabelecimentoSelecionado" />
            <button type="button" @click="lerDados()">Pesquisar</button>
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th>Protocolo</th>
                        <th>Vistoria</th>
                        <th>Registro</th>
                        <th>Estabelecimento</th>
                        <th>Situação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pla08, index) in lsDados" :key="pla08.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                        <td>{{ pla08.xsequencia }}</td>
                        <td>{{ formatDate(pla08.dtvistoria) }}</td>
                        <td>{{ formatDate(pla08.dtlancamento) }}</td>
                        <td>{{ pla08.nmestabelecimento }}</td>
                        <td>{{ pla08.xstatus }}</td>
                        <td>
                            <div class="row-left">
                                <button v-if="pla08.status >= 4" class="btn-icon btn-icon--pdf" @click="lerAnexo(pla08.id)"></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import EstabelecimentoSelect from '@/components/selects/EstabelecimentoSelect.vue';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import RangeDataSelect from '@/components/selects/RangeDataSelect.vue';

export default{
    name: 'Pla08ListaInspetorPage',
    components: {EstabelecimentoSelect, MensagemAlerta, RangeDataSelect},
    methods:{
        formatDate(dateStr){
            const[year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        }
    },
    setup(){
        const errorMessage = ref(null);
        const estabelecimentoSelecionado = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const lsEstabelecimentos = ref([]);
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});

        const inicializar = async() => {
            try{
                isLoading.value = true;
                try{
                    const rEst = await genericPost('/listas/estabelecimentos', { idep: login.userData.value.idep, idsim: login.userData.value.idsim });
                    lsEstabelecimentos.value = rEst;
                    lsEstabelecimentos.value.unshift({id: 0, nmestabelecimento: 'TODOS'});
                }catch(error){console.error(error);}
            }finally{isLoading.value=false;}
        }

        const lerAnexo = async(idPla) => {
            try{
                isLoading.value = true;
                const dados = {id: 0, idreferencia: idPla, strparam1: 'L'};
                const req = await genericPost('/lspla/pla08_anexo_base64', dados);
                const pdfBase64 = req.anexo;
                const pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+encodeURIComponent(pdfBase64) +"'></iframe>'");
            } catch (error) {console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try {
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: (estabelecimentoSelecionado.value ? estabelecimentoSelecionado.value.id : 0),
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const req = await genericPost('/lspla/pla08', dados);
                lsDados.value = req;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        };

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
            console.debug(periodoSelecionado.value);
        }

        onMounted(()=>{inicializar();});

        return{
            errorMessage, estabelecimentoSelecionado, isLoading, lsDados, lsEstabelecimentos,
            lerAnexo, lerDados, rangeDataChange
        }        
    }
}
</script>