import { reactive } from 'vue';

export const criarAnexoUpload = () => {
  return reactive({
    id: 0,
    idanexogrupo: 0,
    idep: 0,
    idsim: 0,
    idtpdoc: 0,
    idestabelecimento: 0,
    nmdoc: '',
    dhpostagem: '',
    idupostagem: 0,
    obspostagem: '',
    dtvalidade: '',
    fname: '',
    tipo: '',
    origemsistema: 'SGSIM-WEB',
    origemversao: '1.24.1104',
    anexo: '',
    idusuario: 0
  });
};