import { reactive, toRefs } from 'vue';
import { useStore } from 'vuex';

export function usuarioLogado() {
  const store = useStore();
  const state = reactive({
    userData: store.getters.getUserData,
  });

  return {
    ...toRefs(state),
  };
}
