<template>
    <div class="layout">
        <aside class="sidebar" :class="{ collapsed: isCollapsed }">
            <ul>
                <li @click="toggleMenu">
                    <img :src="menuIcon" alt="Toggle Menu" />
                    <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
                </li>

                <li @click="setComponenteAtivo('SimsInspetorPage','SERVIÇOS DE INSPEÇÃO')">
                    <img src="@/assets/icons/menuSims.png" alt="Serviços de Inspeção" />
                    <span>Serviços de Inspeção</span>
                </li>

                <li @click="setComponenteAtivo('EstabelecimentosInspetorLista', 'ESTABELECIMENTOS')">
                    <img src="@/assets/icons/menuEstabelecimentos.png" alt="Estabelecimentos" />
                    <span>Estabelecimentos</span>
                </li>

                <li @click="setComponenteAtivo('ListaAtividadesPage', 'HISTÓRICO DE ATIVIDADES')">
                    <img src="@/assets/icons/menuHistory.png" />
                    <span>Histórico de Atividades</span>
                </li>

                <li @click="setComponenteAtivo('Pla08ListaInspetorPage', 'AÇÕES CORRETIVAS')">
                    <img src="@/assets/icons/menuRAC.png" />
                    <span>Ações Corretivas (RAC)</span>
                </li>

                <li @click="setComponenteAtivo('Pla13ListaInspetorPage', 'PLANILHAS DE PRODUÇÃO')">
                    <img src="@/assets/icons/menuProducao.png" />
                    <span>Planilhas de Produção</span>
                </li>

                <li @click="setComponenteAtivo('AnexosInspetorListaPage', 'DOCUMENTAÇÕES DE ESTABELECIMENTOS')">
                    <img src="@/assets/icons/menuDocumentos.png" />
                    <span>Análise Documentação</span>
                </li>

                <li @click="setComponenteAtivo('UsuariosRTListaPage', 'RESPONSÁVEIS TÉCNICOS')">
                    <img src="@/assets/icons/menuRt.png" alt="RT's" />
                    <span>Responsáveis Técnicos</span>
                </li>

                <li @mouseover="showRelatoriosMenu = true" @mouseleave="showRelatoriosMenu = false">
                    <img src="@/assets/icons/menuPrinter.png" />
                    <span>Relatórios</span>
                    <div v-if="showRelatoriosMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul><li @click="selectOption('EstebelecimentosParecerReportPage', 'RELATÓRIO: PARECER ESTABELECIMENTOS')">
                            <span>Estabelecimentos - Parecer Analítico</span>
                        </li></ul>

                        <ul><li @click="selectOption('NCAnaliticoReportPage', 'RELATÓRIO: ANALÍTICO DE NÃO CONFORMIDADES')">
                            <span>Não Conformidades - Analítico</span>
                        </li></ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo('UsuarioEditPage', 'ALTERAÇÃO DE DADOS')">
                    <img src="@/assets/icons/menuEditUser.png" alt="Login/Senha" />
                    <span>Alteração de Cadastro</span>
                </li>

                <li @click="setComponenteAtivo('AlterarLoginPage', 'ALTERAÇÃO DE LOGIN E SENHA')">
                    <img src="@/assets/icons/menuPass.png" alt="Login/Senha" />
                    <span>Redefinir Login e Senha</span>
                </li>

                <li class="logout" @click="confirmLogout">
                    <img src="@/assets/icons/menuLogout.png" alt="Desconectar" />
                    <span>Desconectar</span>
                </li>
            </ul>
        </aside>


        <main class="content">
            <header class="header">
                <div class="header-title">{{ nmTitulo ? nmTitulo : 'INSPETOR' }}</div>
                <div class="user-info">
                    <div class="user-name">{{ userData.nmusuario }}</div>
                    <div class="establishment-name">{{ userData.nmsim ? userData.nmsim : 'Aguardando sim...' }}</div>
                    <div class="establishment-name">Tempo restante do token: {{ timeRemaining }}</div>
                </div>
            </header>
            <div class="main-content">
                <slot>
                    <div v-if="nmComponenteAtivo=='AlterarLoginPage'"><AlterarLoginPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='AnexosInspetorListaPage'"><AnexosInspetorListaPage @close="setComponenteAtivo('', '')"/></div>
                    <div v-else-if="nmComponenteAtivo=='EstabelecimentosInspetorLista'"><EstabelecimentosInspetorLista @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EstebelecimentosParecerReportPage'"><EstebelecimentosParecerReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='ListaAtividadesPage'"><ListaAtividadesPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='NCAnaliticoReportPage'"><NCAnaliticoReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='Pla08ListaInspetorPage'"><Pla08ListaInspetorPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='Pla13ListaInspetorPage'"><Pla13ListaInspetorPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='SimsInspetorPage'"><SimsInspetorPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuarioEditPage'"><UsuarioEditPage :idEdicao="userData.id" @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosRTListaPage'"><UsuariosRTListaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else><ResumoGeralPage /></div>
                </slot>
            </div>
        </main>
    </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useStore } from "vuex";
import AlterarLoginPage from '@/components/cadastros/AlterarLoginPage.vue';
import AnexosInspetorListaPage from '../listas/AnexosInspetorListaPage.vue';
import EstabelecimentosInspetorLista from '@/components/listas/EstabelecimentosInspetorLista.vue';
import EstebelecimentosParecerReportPage from '../reports/EstebelecimentosParecerReportPage.vue';
import ListaAtividadesPage from '@/components/planilhas/ListaAtividadesPage.vue';
import NCAnaliticoReportPage from '../reports/NCAnaliticoReportPage.vue';
import Pla08ListaInspetorPage from '../planilhas/pla08/Pla08ListaInspetorPage.vue';
import Pla13ListaInspetorPage from '../planilhas/pla13/Pla13ListaInspetorPage.vue';
import ResumoGeralPage from '@/components/dashboards/ResumoGeralPage.vue';
import SimsInspetorPage from '@/components/listas/SimsInspetorLista.vue';
import UsuarioEditPage from '@/components/cadastros/formularios/UsuarioEditPage.vue';
import UsuariosRTListaPage from '@/components/listas/UsuariosRTListaPage.vue';

export default{
    name: 'MainInspetor',
    components:{
        AlterarLoginPage,
        AnexosInspetorListaPage,
        EstabelecimentosInspetorLista,
        EstebelecimentosParecerReportPage,
        ListaAtividadesPage,
        NCAnaliticoReportPage,
        Pla08ListaInspetorPage,
        Pla13ListaInspetorPage,
        ResumoGeralPage,
        SimsInspetorPage,
        UsuarioEditPage,
        UsuariosRTListaPage
    },
    computed: {
        menuIcon() {
            return this.isCollapsed
                ? require('@/assets/icons/menuExpand.png')
                : require('@/assets/icons/menuCollapse.png');
        }
    },
    setup(){
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const showRelatoriosMenu = ref(false);
        const store = useStore();
        const timeRemaining = ref(null);
        const userData = computed(() => store.getters.getUserData);
        let timer = null;

        const confirmLogout = () => {
            resetLogin();
        }

        const resetLogin = () => {store.dispatch('clearSessionData');}

        const selectOption = (page, titulo) => {
            nmComponenteAtivo.value = page;
            nmTitulo.value = titulo;
            showRelatoriosMenu.value = false;
        }

        const setComponenteAtivo = (nome, titulo) => {
            nmComponenteAtivo.value = nome;
            nmTitulo.value = titulo;
        }

        const toggleMenu = () => {isCollapsed.value = !isCollapsed.value;}

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        timeRemaining.value = `${hours}h ${minutes}m ${seconds}s`;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });
        
        return{ 
            isCollapsed, nmComponenteAtivo, nmTitulo, showRelatoriosMenu, timeRemaining, userData, 
            confirmLogout, selectOption, setComponenteAtivo, toggleMenu };
    }
}
</script>