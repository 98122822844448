<template>
    <div class="center">
        <img alt="iMolgaro Engenharia de Sistemas" src="@/assets/imolgaro.png" width="210px">
        <div></div>
        <h2 style="margin-top: 40px;">SISTEMA DE GESTÃO DOS<br>SERVIÇOS DE INSPEÇÃO MUNICIPAL</h2>
      
        <div style="margin-top: 40px;">
            <form>
                <label>Usuário:</label><br>
                <input v-model="credenciais.userName" type="text"  autocomplete="username"><br><br>
            
                <label>Senha:</label><br>
                <input v-model="credenciais.passWord" type="password" autocomplete="current-password"><br><br>
            </form>
        
            <button @click="loginNew" class="login-button" :disabled="isLoading">
                {{ isLoading ? 'Autenticando...' : 'LOGIN' }}
            </button><br><br>
            
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
    </div>
</template>


<script>
import { computed, reactive, ref } from 'vue';
import { genericPost, loginAPI } from '@/funcoes/api'; 
import { useStore } from "vuex";

export default {
    name: 'LoginPage',

    setup() {
        const store = useStore();
        const userData = computed(() => store.getters.getUserData);
        const credenciais = reactive({userName: '', passWord: ''});
        const isLoading = ref(false);
        const errorMessage = ref('');

        const loginNew = async() => {
            try{
                isLoading.value = true;
                errorMessage.value = "";
                const dados = { appname: 'SGSIM-WEB', appver: '1.24.1106', login: credenciais.userName, senha: credenciais.passWord };
                const loginSuccess = await loginAPI(credenciais.userName, credenciais.passWord, dados);
                if (loginSuccess){
                    const req = await genericPost('/main/registrarlogin', dados);
                    if (req){
                        store.dispatch('updateUserData', req);
                    }
                }else{errorMessage.value = "Login não autorizado"}        
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        return { credenciais, userData, loginNew, isLoading, errorMessage };
    }
}
</script>

<style scoped>
h2{
    color: navy;
}
label{
    padding: 30;
    margin-bottom: 5px;
    color: navy;
    font-weight: bold;
    font-size: 0.9em;
}

input{
    border-radius: 5px;
    padding: 2px 4px 5px 4px;
}

.center {
    border-color: #7c8a6e;
    border-style: solid;
    border-radius: 5%;
    border-width: 4px;
    background-color: #eef2e6;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-button {
    padding: 5px 30px;
    font-size: 16px;
    color: white;
    background-color: navy;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.login-button:hover {
    background-color: #0056b3;
}
.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}
</style>