<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome SIM</th>
                    <th>Fone</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sim, index) in lsDados" :key="sim.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ sim.idsim }}</td>
                    <td>{{ sim.nmsim }}</td>
                    <td>{{ sim.fone }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(sim)"></button>
                        <button class="btn-icon btn-icon--checkMark" @click="setSimAtivo(sim)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoEdicao">
        <SimEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { useStore } from "vuex";
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api'; 
import SimEditPage from '../cadastros/formularios/SimEditPage.vue';

export default{
    name: 'SimsInspetorPage',
    components:{SimEditPage},
    emits: ['close'],

    setup(_, {emit}){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const store = useStore();

        const editarCadastro = (sim) => {
            idEdicao.value = sim.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const dados = {idep: login.userData.value.idep, idusuario: login.userData.value.id};
                const req = await genericPost('/listas/sims', dados);
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const setSimAtivo = (sim) => {
            try{
                store.dispatch('updateSimFields', { idsim: sim.id, nmsim: sim.nmsim });
                emit('close');
            } catch (error) {console.error('Erro setar SIM', error);}
        };

        lerDados();

        return { editarCadastro, lerDados, setSimAtivo, idEdicao, isLoading, lsDados, modoEdicao };
    }
}
</script>
