import { reactive } from 'vue';

export const criarEstabelecimento = () => {
  return reactive({
    id: 0,
    idep: 0,
    idsim: 0,
    cdestabelecimento: 0,
    idestabelecimento: 0,
    idatividade: 0,
    idrt: 0,
    nmestabelecimento: '',
    nmfantasia: '',
    endereco: '',
    nmendereco: 0,
    complemento: '',
    bairro: '',
    idmunicipio: 0,
    cep: '',
    fone: '',
    fone2: '',
    fax: '',
    cpfcnpj: '',
    cpfcnpjnros: '',
    ie: '',
    im: '',
    nrosim: '',
    email: '',
    email2: '',
    nmcontato: '',
    celularcontato: '',
    emailcontato: '',
    rtnome: '',
    rtfone: '',
    rtemail: '',
    obs: '',
    ativo: '',
    xstatus: '',
    cadsebraetec: '',
    ckdadosmoveis: '',
    ckimpressora: '',
    ckinternet: '',
    latitude: 0,
    longitude: 0,
    idusuario: 0
  });
};
