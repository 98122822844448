<template>
    <div class="change-login">
        <h3>Redefinir Login e Senha</h3><br>
        <form @submit.prevent="enviarDados">
            <div class="form-group">
                <label class="label-caption" for="login">Login:</label>
                <input type="text" id="login" v-model="novoLogin" required>
            </div>
            <div class="form-group">
                <label class="label-caption" for="nova-senha">Nova Senha:</label>
                <input type="password" id="nova-senha" autocomplete="username" v-model="novaSenha" required>
            </div>
            <div class="form-group">
                <label class="label-caption" for="confirmar-senha">Confirmar Senha:</label>
                <input type="password" id="confirmar-senha" autocomplete="password" v-model="confirmarSenha" required>
            </div>
            <div class="buttons">
                <button type="submit">Gravar</button>
                <button type="button" class="btn-cancel" @click="cancelar">Cancelar</button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from "vuex";
import { genericPut, loginDisponivel } from '@/funcoes/api'; 
import { usuarioLogado } from '@/composables/usuario';

export default {
    name: 'AlterarLoginPage',
    emits:['close'],

    mounted(){
        this.novoLogin = this.usuario.userData.value.login;
    },

    setup(_, { emit }){
        const novoLogin = ref('');
        const novaSenha = ref('');
        const confirmarSenha = ref('');
        const store = useStore();
        const usuario = usuarioLogado();

        const enviarDados = async() => {
            if (!novaSenha.value){return}
            if (novaSenha.value !== confirmarSenha.value){
                alert('As senhas não coincidem.');
                return;
            }
            try{
                const ok = await loginDisponivel(usuario.userData.value.id, novoLogin.value);
                if (ok){
                    const response = await genericPut('/cad/usuario', { id: usuario.userData.value.id, login: novoLogin.value, senha: novaSenha.value });
                    if (response.bolok==1){
                        store.dispatch('updateLoginName', novoLogin.value);
                    }
                    emit('close');
                } else {
                    alert('Nome de usuário já está sendo utilizado, necessário cadastrar outro');
                }
            }catch (error){console.error('Error:', error);}
        }

        const cancelar = () => {emit('close');}

        return { novoLogin, novaSenha, confirmarSenha, usuario, cancelar, enviarDados };
    }
}
</script>
  
<style scoped>
.change-login {
    max-width: 350px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 10px;
}
</style>
<style scoped src="@/assets/css/layout.css"></style>
  