<template>
    <div>
        <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <RangeDataSelect @rangeChange="rangeDataChange" />
            <button type="button" @click="lerDados()">Pesquisar</button>
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th>Ano/Mês</th>
                        <th>Protocolo</th>
                        <th>Responsável</th>
                        <th>Situação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pla13, index) in lsDados" :key="pla13.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                        <td>{{ pla13.anomes }}</td>
                        <td>{{ pla13.protocolo }}</td>
                        <td>{{ pla13.nmresponsavel }}</td>
                        <td>{{ pla13.xstatus }}</td>
                        <td>
                            <div class="row-left">
                                <button class="btn-icon btn-icon--pdf" @click="lerAnexo(pla13.id)"></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPost } from '@/funcoes/api';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import RangeDataSelect from '@/components/selects/RangeDataSelect.vue';

export default{
    name: 'Pla13Lista',
    components: {MensagemAlerta, RangeDataSelect},
    setup(){
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});

        const lerAnexo = async(idPla) => {
            try{
                isLoading.value = true;
                const dados = {id: 0, idreferencia: idPla, strparam1: 'L'};
                const req = await genericPost('/lspla/pla13_anexo_base64', dados);
                const pdfBase64 = req.anexo;
                const pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, "+
                    encodeURIComponent(pdfBase64) +
                    "'></iframe>'"
                );
            } catch (error) {console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        };

        const lerDados = async() => {
            try {
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idestabelecimento: login.userData.value.idestabelecimento,
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const req = await genericPost('/lspla/pla13', dados);
                lsDados.value = req;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        };

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
            console.debug(periodoSelecionado.value);
        }

        return{
            errorMessage, isLoading, lsDados,
            lerAnexo, lerDados, rangeDataChange
        }
    }
}
</script>