<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando dados...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ cdmunicipio }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmmunicipio">Nome Município</label>
                    <input type="text" v-model="nmmunicipio" id="nmmunicipio" required maxlength="80" @input="nmmunicipio = nmmunicipio.toUpperCase()" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="idibge">IBGE</label>
                    <input type="text" class="input-right" v-model="formatedIbge" id="idibge" v-number maxlength="9" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="uf">UF</label>
                    <input type="text" v-model="uf" id="uf" maxlength="2" @input="uf = uf.toUpperCase()" />
                </div>
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" @click="cancelForm">Cancelar</button>
            </div>            
        </form>
    </div>
</template>
  
<script>
import { criarMunicipio } from '@/models/municipio';
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById } from '@/funcoes/api';

export default {
    name: 'MunicipioEditPage',
    computed: {
        formatedIbge:{
            get(){return this.formatNumber(this.idibge);},
            set(value){this.idibge = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props:{
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const municipio = criarMunicipio();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const cancelForm = () => {emit('close');}

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    isLoading.value = true;
                    try{
                        const req = await getCadastroById('municipio', props.idEdicao);
                        Object.assign(municipio, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }else{
                municipio.idep = login.userData.value.idep;
            }
            municipio.idusuario = login.userData.value.id;
        };

        const submitForm = async() => {
            try{
                const response = await genericPut('/cad/municipio', municipio);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        lerDados();

        return { ...toRefs(municipio), isLoading, cancelForm, submitForm };
    }
};
</script>