<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <RangeDataSelect @rangeChange="rangeDataChange" />
        <button type="button" @click="openReport">IMPRIMIR</button>
    </div>

    <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
</template>

<script>
import { ref } from 'vue';
import { genericReport } from '@/funcoes/api';
import { usuarioLogado } from '@/composables/usuario';
import MensagemAlerta from '@/components/utils/MensagemAlerta.vue';
import RangeDataSelect from '../selects/RangeDataSelect.vue';

export default{
    name: 'NCAnaliticoReportPage',
    components: { MensagemAlerta, RangeDataSelect },
    setup(){
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const periodoSelecionado = ref({dataInicio: '', dataFim: ''});

        const openReport = async() => {
            try{
                if (!periodoSelecionado.value.dataInicio || !periodoSelecionado.value.dataFim){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(periodoSelecionado.value.dataInicio);
                const dataFim = new Date(periodoSelecionado.value.dataFim);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'nc_analitico',
                    idep: login.userData.value.idep,
                    idsim: login.userData.value.idsim,
                    idusuario: login.userData.value.idusuario,
                    idestabelecimento: login.userData.value.idestabelecimento,
                    datainicial: periodoSelecionado.value.dataInicio,
                    datafinal: periodoSelecionado.value.dataFim
                };
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const rangeDataChange = (range) => {
            periodoSelecionado.value = range;
            console.debug(periodoSelecionado.value);
        }

        return { errorMessage, isLoading, openReport, rangeDataChange };
    }
}
</script>