<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <table>
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Município</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(ep, index) in lsDados" :key="ep.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td>{{ ep.id }}</td>
                    <td>{{ ep.nmep }}</td>
                    <td>{{ ep.nmmunicipio }}</td>
                    <td><div class="row-left">
                        <button class="btn-icon btn-icon--edit" @click="editarCadastro(ep)"></button>
                        <button class="btn-icon btn-icon--checkMark" @click="setEpAtivo(ep)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div class="buttons margin-top10">
            <button type="button" @click="closeForm()">Sair</button>
        </div>
    </div>


    <div v-if="modoEdicao">
        <EpEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>          
</template>


<script>
import { ref } from 'vue';
import { useStore } from "vuex";
import { usuarioLogado } from '@/composables/usuario';
import { genericGet } from '@/funcoes/api'; 
import EpEditPage from '../cadastros/formularios/EpEditPage.vue';

export default{
    name: 'EpListaPage',
    components:{EpEditPage},
    emits: ['close'],

    setup(props, {emit}){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = usuarioLogado();
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const store = useStore();

        const closeForm = () => {emit('close');}

        const editarCadastro = (ep) => {
            idEdicao.value = ep.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
            isLoading.value = true;
            const req = await genericGet('/listas/eps');
            lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const setEpAtivo = (ep) => {
            try{
            login.userData.value.idep = ep.id;
            login.userData.value.nmep = ep.nmep;
            store.dispatch('updateUserData', Object.assign({}, login.userData.value));
            emit('close');
            } catch (error) {console.error('Erro EP', error);}
        };

        lerDados();

        return { closeForm, editarCadastro, lerDados, setEpAtivo, idEdicao, isLoading, lsDados, modoEdicao };
    }
}
</script>
