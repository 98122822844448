import { reactive } from 'vue';

export const criarContaEmail = () => {
  return reactive({
    id: 0,
    idep: 0,
    nmconta: '',
    nmremetente: '',
    smtphost: '',
    smtpport: 0,
    smtpuser: '',
    smtppass: '',
    emailssl: '',
    emailtls: '',
    ativo: 'S',
    idusuario: 0
  });
};