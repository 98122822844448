<template>
    <div v-if="isLoading" class="loading-overlay">
        <p>Carregando dados...</p>
    </div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="form-group form-row">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ id }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmconta">Nome Conta</label>
                    <input type="text" v-model="nmconta" id="nmconta" required maxlength="80" @input="nmconta = nmconta.toUpperCase()" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="nmremetente">Nome Remetente</label>
                    <input type="text" v-model="nmremetente" id="nmremetente" required maxlength="80" @input="nmremetente = nmremetente.toUpperCase()" />
                </div>
            </div>

            <div class="form-group form-row">
                <div class="form-item flexible">
                    <label class="label-caption" for="smtphost">Host</label>
                    <input type="text" v-model="smtphost" id="smtphost" maxlength="80" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="smtpuser">Nome Usuário</label>
                    <input type="text" v-model="smtpuser" id="smtpuser" maxlength="80" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="smtppass">Senha</label>
                    <input type="password" v-model="smtppass" id="smtppass" autocomplete="password" maxlength="80" />
                </div>
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption" for="smtpport">Porta</label>
                    <input type="text" class="input-right" v-model="formatedSmtpPort" id="smtpport" v-number maxlength="9" />
                </div>
            </div>

            <div class="form-group buttons">
                <label>Email SSL</label>
                <input type="checkbox" class="input-checkbox" id="emailssl" v-model="ckEmailSSL" />
            </div>

            <div class="form-group buttons">
                <label>Email TLS</label>
                <input type="checkbox" class="input-checkbox" id="emailtls" v-model="ckEmailTLS" />
            </div>

            <div class="form-group buttons">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="form-group buttons">
                <button type="submit">Salvar</button>
                <button type="button" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { criarContaEmail } from '@/models/contaEmail';
import { numberFormatMixin } from '@/mixins/numberFormatMixin';
import { computed, ref, toRefs } from 'vue';
import { usuarioLogado } from '@/composables/usuario';
import { genericPut, getCadastroById } from '@/funcoes/api';

export default {
    name: 'ContaEmailEditPage',
    computed: {
        formatedSmtpPort:{
            get(){return this.formatNumber(this.smtpport);},
            set(value){this.smtpport = this.unformatNumber(value);}
        }
    },
    emits: ['close', 'salvo'],
    mixins: [numberFormatMixin],
    props:{
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const contaEmail = criarContaEmail();
        const isLoading = ref(false);
        const login = usuarioLogado();

        const ckAtivo = computed({
            get() {return contaEmail.ativo === 'S';},
            set(value) {contaEmail.ativo = value ? 'S' : 'N';}
        });

        const ckEmailSSL = computed({
            get() {return contaEmail.emailssl === 'S';},
            set(value) {contaEmail.emailssl = value ? 'S' : 'N';}
        });

        const ckEmailTLS = computed({
            get() {return contaEmail.emailtls === 'S';},
            set(value) {contaEmail.emailtls = value ? 'S' : 'N';}
        });

        const cancelForm = () => {emit('close');}

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    isLoading.value = true;
                    try{
                        const req = await getCadastroById('contaemail', props.idEdicao);
                        Object.assign(contaEmail, req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }else{
                contaEmail.idep = login.userData.value.idep;
            }
            contaEmail.idusuario = login.userData.value.id;
        };

        const submitForm = async() => {
            try{
                const response = await genericPut('/cad/contaemail', contaEmail);
                emit('salvo');
                emit('close');
                console.log('Success', response);
            }catch (error){console.error('Error:', error);}
        }

        lerDados();

        return { ...toRefs(contaEmail), isLoading, ckAtivo, ckEmailSSL, ckEmailTLS, cancelForm, submitForm };
    }
};
</script>